import { generateApplyRequest } from "../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/orders.cart.create.helper";
import {action, computed, makeObservable, observable} from "mobx";
import {
    OrdersCheckoutShipping,
    OrdersCheckoutShippingQuery,
} from "../../../../models/orders/orders-cart/orders-checkout/orders.checkout.shipping";
import { OrdersCheckoutAddress } from "../../../../models/orders/orders-cart/orders-checkout/orders.checkout.address";
import { CustomerAccountAddress } from "../../../../models/accounts/customer-account/address/customer.account.address";
import { agentDataService } from "../../../../services/agents/agent.data.service";
import { OrdersCheckoutTableItem } from "../../../../models/orders/orders-cart/orders-checkout/orders.checkout.table";
import { OrdersCartEditFormValues } from "../../../../models/orders/orders-cart/orders.cart.edit.form.values";
import { CustomerAccount } from "../../../../models/accounts/customer-account/customer.account";
import { ordersCheckoutService } from "../../../../services/orders/orders-cart/orders.checkout.service";
import React from "react";
import {
    getDataFromUrl,
    getStringifiedQueryParams,
} from "../../../../utils/helpers";
import { OrdersCheckoutRequest } from "../../../../models/orders/orders-cart/orders-checkout/orders.checkout.request";
import { NotCompletedOrder } from "../../../../models/orders/orders-cart/orders-checkout/orders.checkout.not_completed.order";
import { OrdersCreateAddValues } from "../../../../models/orders/orders-cart/orders-create/orders.create.add.values";
import { articlesService } from "../../../../services/articles/articles.service";
import {orderDataService} from "../../../../services/orders/order-data/order.data.service";
import {OrdersUpdateRequest} from "../../../../models/orders/order-update/order.update";
import dayjs from "dayjs";

export class OrdersCheckoutStore {
    public editFormValues: OrdersCartEditFormValues | null = null;

    @observable
    public cancellingOrder: NotCompletedOrder | null = null;

    @observable
    public cancelOrderModalShown: boolean = false;

    @observable
    public ordersCancelling: { [orderId: number]: boolean } = {};

    @observable
    public applyButtonDisabled: boolean = false;

    @observable
    public checkoutOrderDisabled: boolean = false;

    @observable
    public notCompletedOrders: NotCompletedOrder[] = [];

    @observable
    public activeTabs: number[] = [];

    @observable
    public loading: boolean = false;

    @observable
    public applyTableData: OrdersCheckoutTableItem[] = [];

    @observable
    public agentShippingAddresses: {
        [orderId: string]: OrdersCheckoutAddress[];
    } = {};

    @observable
    public shippings: { [orderId: number]: OrdersCheckoutShipping[] } = {};

    @observable
    public agentShippingsNotFound: { [orderId: number]: boolean } = {};

    @observable
    public ordersResult: { [orderId: number]: boolean } = {};

    @observable
    public relatedOrderNumber: { [orderId: number]: string } = {};

    @observable
    public orderComment: { [orderId: number]: string } = {};

    @observable
    public urlQueryString?: string;

    @observable
    public notValidItemsModalShown: boolean = false;

    private ordersCheckoutRequests: {
        [orderId: number]: OrdersCheckoutRequest;
    } = {};

    @observable
    private notValidItemsOrder: NotCompletedOrder | null = null;

    @observable
    public addModalShown: boolean = false;

    @observable
    public ordersDiscount: { [orderId: number]: number } = {};

    constructor(
        private account: CustomerAccount,
        private decreaseNotCompletedOrdersAmount: () => void,
        private updateNotCompletedOrdersAmount: (amount: number) => void
    ) {
        makeObservable(this);
        this.getCart();
    }

    @action
    private setOrdersDiscount(orderId: number, value: number): void {
        this.ordersDiscount[orderId] = value;
    }

    @action
    private setAddModalShown(value: boolean): void {
        this.addModalShown = value;
    }

    @action
    public setCancellingOrder(value: null | NotCompletedOrder): void {
        this.cancellingOrder = value;
    }

    @action
    private setActiveTabs(value: number[]): void {
        this.activeTabs = value;
    }

    @action
    public addActiveTab(orderId: number): void {
        this.activeTabs = [...this.activeTabs, orderId];
    }

    @action
    public setCancelModalShown(value: boolean): void {
        this.cancelOrderModalShown = value;
    }

    @action
    private setNotValidItemsModalShown(value: boolean): void {
        this.notValidItemsModalShown = value;
    }

    @action
    private setOrdersCancelling(orderId: number, value: boolean): void {
        this.ordersCancelling[orderId] = value;
    }

    @action
    private setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    private setCheckoutOrderDisabled(value: boolean): void {
        this.checkoutOrderDisabled = value;
    }

    @action
    public setRelatedOrderNumberValue(
        order: NotCompletedOrder,
        value: string
    ): void {
        this.relatedOrderNumber[order.id] = value;
    }

    @action
    public setOrderCommentValue(order: NotCompletedOrder, value: string): void {
        this.orderComment[order.id] = value;
    }

    @action
    private setOrderResult(orderId: number, result: boolean): void {
        this.ordersResult[orderId] = result;
    }

    @action
    private setAgentAddresses(
        orders: NotCompletedOrder[],
        addresses: CustomerAccountAddress[],
        type: "billing" | "shipping"
    ): void {
        const newAddresses: { [warehouse: string]: OrdersCheckoutAddress[] } =
            {};
        orders.forEach((order) => {
            newAddresses[order.id] = addresses.map((address) => ({
                ...address,
                checked: address.default,
                custom: false,
            }));
        });

        if (type === "shipping") {
            this.agentShippingAddresses = newAddresses;
        }
    }

    @action
    private updateAddressesData(
        addresses: { [orderId: string]: OrdersCheckoutAddress[] },
        order: NotCompletedOrder,
        index: number,
        checked: boolean
    ) {
        addresses[order.id] = addresses[order.id].map((address) => ({
            ...address,
            checked: false,
        }));
        addresses[order.id][index].checked = checked;

        if (addresses[order.id].every((item) => !item.checked)) {
            addresses[order.id][index].checked = true;
        }
    }

    private setOrdersCheckoutRequest(
        orderId: number,
        request: OrdersCheckoutRequest
    ): void {
        this.ordersCheckoutRequests[orderId] = request;
    }

    @action
    private setNotValidItemsModalItem(order: NotCompletedOrder | null): void {
        this.notValidItemsOrder = order;
    }

    public updateAgentShippingAddresses = async (
        order: NotCompletedOrder,
        address: OrdersCheckoutAddress,
        checked: boolean
    ) => {
        const oldAddress = this.agentShippingAddresses[order.id].find(
            (address) => address.checked
        );
        const oldCountry = oldAddress!.country.id;
        const oldIndex = oldAddress!.index;

        const addressIndex =
            this.agentShippingAddresses[order.id].indexOf(address);
        if (
            addressIndex !== -1 &&
            !this.agentShippingAddresses[order.id].every(
                (item) => !item.checked
            )
        ) {
            this.updateAddressesData(
                this.agentShippingAddresses,
                order,
                addressIndex,
                checked
            );

            const newAddress = this.agentShippingAddresses[order.id][addressIndex];
            const newCountry = newAddress.country.id;
            const newIndex = newAddress.index;

            if (newCountry !== oldCountry || oldIndex !== newIndex) {
                await this.updateShipping(order, newCountry as string, newIndex);
                await this.updateCheckoutCartWithShippingData(address, order);
            }
        }
    };

    @action
    public setNotCompletedOrders(orders: NotCompletedOrder[]): void {
        this.notCompletedOrders = orders;
    }

    @action
    public setAgentShippingsNotFound(orders: NotCompletedOrder[]): void {
        const out: { [orderId: string]: boolean } = {};
        orders.forEach((order) => {
            out[order.id] = false;
        });
        this.agentShippingsNotFound = out;
    }

    @action
    public editAgentShippingsNotFound(
        orderId: number,
        value: boolean
    ): void {
        this.agentShippingsNotFound[orderId] = value;
    }

    @action
    private setShippings(
        orderId: number,
        shippings: OrdersCheckoutShipping[]
    ): void {
        this.shippings[orderId] = shippings;
    }

    @action
    public selectShipping(
        order: NotCompletedOrder,
        shippingId: string,
        checked: boolean
    ): void {
        const shippingIndex = this.shippings[order.id].findIndex(
            (shipping) => shipping.id === shippingId
        );
        if (shippingIndex !== -1) {
            this.shippings[order.id] = this.shippings[order.id].map(
                (shipping) => ({
                    ...shipping,
                    checked: false,
                })
            );
            this.shippings[order.id][shippingIndex].checked = checked;

            if (this.shippings[order.id].every((item) => !item.checked)) {
                this.shippings[order.id][shippingIndex].checked = true;
            }
        }
    }

    @action
    private addAddress(orderId: number, address: OrdersCheckoutAddress): void {
        if (address.type === "shipping") {
            this.agentShippingAddresses[orderId].push(address);
        }
    }

    @action
    private resetShippingAddresses(order: NotCompletedOrder): void {
        this.agentShippingAddresses[order.id] = this.agentShippingAddresses[
            order.id
        ].map((item) => ({
            ...item,
            checked: false,
        }));
    }

    @action
    public setUrlString(value: string): void {
        this.urlQueryString = value;
    }

    public openAddModal = (): void => {
        this.setAddModalShown(true);
    };

    public closeAddModal = (): void => {
        this.setAddModalShown(false);
    };

    public async addCustomAddress(
        values: OrdersCheckoutAddress,
        order: NotCompletedOrder
    ) {
        if (values.type === "shipping") {
            this.resetShippingAddresses(order);
            await this.updateShipping(order, values.country.id as string, values.index);
            await this.updateCheckoutCartWithShippingData(values, order);
        }
        this.addAddress(order.id, values);
    }

    public async addNewAgentAddress(values: CustomerAccountAddress) {
        const newAddress = await agentDataService.createAddress(
            values,
            this.account.id
        );
        this.notCompletedOrders.forEach((order) => {
            this.addCustomAddress(
                { ...newAddress, checked: true, custom: false },
                order
            );
        });
    }

    private static generateShippingDataParams({
        vat,
        customsCode,
        index,
        country,
    }: OrdersCheckoutAddress) {
        const shippingDataParams: { [key: string]: any } = {};
        const shippingData: { [key: string]: any } = {
            vat,
            customsCode,
            index,
            country: country.id,
        };

        for (const item in shippingData) {
            shippingDataParams["shippingData[" + item + "]"] =
                shippingData[String(item)];
        }
        return shippingDataParams;
    }

    private async updateCheckoutCartWithShippingData(
        address: OrdersCheckoutAddress,
        order: NotCompletedOrder
    ) {
        const calculatedOrder =
            await ordersCheckoutService.getCalculatedNotCompletedOrder(
                order.id,
                {
                    ...OrdersCheckoutStore.generateShippingDataParams(address),
                    discountPercent: this.ordersDiscount[order.id],
                }
            );
        this.updateAdvancedOrder(calculatedOrder);
    }

    @action
    private updateAdvancedOrder(order: NotCompletedOrder): void {
        const index = this.notCompletedOrders.findIndex(
            (item) => item.id === order.id
        );
        if (index !== -1) {
            this.notCompletedOrders[index] = order;
        }
    }

    @action
    public removeActiveTab(orderId: number): void {
        const index = this.activeTabs.indexOf(orderId);
        const oldActiveCollapsePanels = [...this.activeTabs];
        oldActiveCollapsePanels.splice(index, 1);
        if (index !== -1) {
            this.activeTabs = oldActiveCollapsePanels;
        }
    }

    public addToCart = async (
        values: OrdersCreateAddValues,
        orderId?: number
    ) => {
        await orderDataService.addOrderItem(orderId!, values);
        await this.reloadOrder(orderId!);
    };

    private async reloadOrder(orderId: number) {
        const calculatedOrder =
            await ordersCheckoutService.getCalculatedNotCompletedOrder(
                orderId,
                {
                    ...OrdersCheckoutStore.generateShippingDataParams(
                        this.agentShippingAddresses[orderId].find(
                            (item) => item.checked
                        )!
                    ),
                    discountPercent: this.ordersDiscount[orderId],
                }
            );
        this.updateAdvancedOrder(calculatedOrder);
    }

    public searchArticle = async (search: string): Promise<any> => {
        const articles = await articlesService.searchArticle(search);
        return articles.map(({ id, name, description }) => ({
            value: id,
            label: name + " - " + description,
        }));
    };

    public async getCart() {
        this.setLoading(true);
        this.setEditFormValues();

        await this.reloadCartCheckout();
        this.notCompletedOrders.forEach((order) => {
            this.setOrdersDiscount(order.id, order.discountPercent);
        });

        this.setUrlString(this.getLink());
        this.addActiveTab(this.notCompletedOrders[0]?.id);
        this.setAgentAddresses(
            this.notCompletedOrders,
            this.account!.shippingAddresses,
            "shipping"
        );

        this.notCompletedOrders.forEach((segment) => {
            this.setRelatedOrderNumberValue(segment, "");
            this.setOrderCommentValue(segment, "");
        });
        this.setLoading(false);
    }

    private async reloadCartCheckout() {
        const orders = await ordersCheckoutService.getNotCompletedOrders(
            this.account.id!,
            this.editFormValues!
        );
        this.setNotCompletedOrders(orders);
        this.updateNotCompletedOrdersAmount(orders.length);
    }

    private setEditFormValues(): void {
        this.editFormValues = getDataFromUrl<OrdersCartEditFormValues>();
    }

    public updateDiscount = async (order: NotCompletedOrder, discount: number) => {
        this.setOrdersDiscount(order.id, discount);
        await this.reloadOrder(order.id);
    }

    public onLngChangeApply = (): void => {
        this.setLoading(true);
        this.reloadCartCheckout();
        this.setLoading(false);
    };

    public deleteOrderItem = async(orderId: number, itemId: number) => {
        await orderDataService.removeOrderItem(itemId);
        await this.reloadOrder(orderId);
    }

    public getStockArticleAmount = async (articleId: number, order: NotCompletedOrder) => {
        return articlesService.getStockAmount(articleId, order.warehouse.id);
    }

    public updateOrderItem = async (itemId: number | string, requestValues: OrdersUpdateRequest, orderId: number) => {
        await orderDataService.updateOrderItem(itemId, requestValues);
        await this.reloadOrder(orderId);
        await this.getShipping(orderId);
    }

    public async getShipping(orderId: number) {
        this.setShippings(orderId, []);
        this.setAgentShippingsNotFound(this.notCompletedOrders);
        const defaultAddress = this.agentShippingAddresses[orderId].find(
            (address) => address.checked
        );
        const query: OrdersCheckoutShippingQuery = {
            country: defaultAddress!.country.id as string,
            index: defaultAddress!.index,
            cityName: defaultAddress!.city,
            cityAreaName: defaultAddress!.cityArea
        };
        const shippings = await ordersCheckoutService
            .getShipping(orderId, query)
            .then((shippings) =>
                shippings.map((shipping, index) => ({
                    ...shipping,
                    checked: index === 0,
                }))
            );
        this.setShippings(orderId, shippings);

        if (!this.shippings[orderId].length) {
            this.editAgentShippingsNotFound(orderId, true);
            this.setCheckoutOrderDisabled(true);
        }
    }

    public async updateShipping(order: NotCompletedOrder, newCountry: string, index: string) {
        const query: OrdersCheckoutShippingQuery = {
            country: newCountry,
            index: index,
            cityName: '',
            cityAreaName: ''
        };
        const shippings = await ordersCheckoutService
            .getShipping(order.id, query)
            .then((shippings) =>
                shippings.map((shipping, index) => ({
                    ...shipping,
                    checked: index === 0,
                }))
            );
        this.setShippings(order.id, shippings);
    }

    public async createOrder(order: NotCompletedOrder) {
        this.setCheckoutOrderDisabled(true);
        const requestData = generateApplyRequest({
            shippingCouriers: this.shippings[order.id],
            relatedOrderNumber: this.relatedOrderNumber[order.id],
            orderComment: this.orderComment[order.id],
            shippingAddresses: this.agentShippingAddresses[order.id],
            discountPercent: this.ordersDiscount[order.id],
        });
        this.setOrdersCheckoutRequest(order.id, requestData);
        try {
            if (order.items.some((item) => !item.valid)) {
                this.setNotValidItemsModalItem(order);
                this.openNotValidItemsModal();
            } else {
                await this.completeCreatingOrder(order);
            }
        } catch (e: any) {
            this.setCheckoutOrderDisabled(false);
        }
    }

    private async completeCreatingOrder(order: NotCompletedOrder) {
        await ordersCheckoutService.completeOrder(
            order.id,
            this.ordersCheckoutRequests[order.id]
        );
        this.setCheckoutOrderDisabled(false);

        setTimeout(() => {
            this.setOrderResult(order.id, true);
            this.decreaseNotCompletedOrdersAmount();

            setTimeout(() => {
                const resultOrderIndex = this.notCompletedOrders.indexOf(order);
                if (resultOrderIndex !== -1) {
                    const nextOrder =
                        this.notCompletedOrders[resultOrderIndex + 1];
                    this.openCollapse(nextOrder);
                }
            }, 200);
        }, 500);
    }

    private openCollapse(order: NotCompletedOrder): void {
        if (order && !this.activeTabs.includes(order.id)) {
            this.addActiveTab(order.id);
        }
    }

    public toggleOrderCollapse(order: NotCompletedOrder): void {
        if (this.activeTabs.includes(order.id)) {
            this.removeActiveTab(order.id);
            return;
        }
        this.addActiveTab(order.id);
    }

    public async onCancelOrder(order: NotCompletedOrder) {
        this.setCancelModalShown(true);
        this.setCancellingOrder(order);
        this.setOrdersCancelling(order.id, true);
    }

    public cancelOrder = async () => {
        await ordersCheckoutService.cancelOrder(this.cancellingOrder!.id);
        this.deleteOrder(this.cancellingOrder!);
        this.setCancellingOrder(null);
        this.setCancelModalShown(false);
    };

    public moveOrderToCart = async () => {
        await ordersCheckoutService.cancelOrder(this.cancellingOrder!.id, {
            move: "1",
        });
        this.deleteOrder(this.cancellingOrder!);
        this.setCancellingOrder(null);
        this.setCancelModalShown(false);
    };

    private deleteOrder(order: NotCompletedOrder): void {
        const index = this.notCompletedOrders.indexOf(order);
        const newOrders = [...this.notCompletedOrders];
        this.setOrdersCancelling(order.id, false);
        newOrders.splice(index, 1);
        if (index !== -1) {
            this.setNotCompletedOrders(newOrders);
            this.decreaseNotCompletedOrdersAmount();
        }
    }

    public closeCancelOrderModal = (): void => {
        this.setCancelModalShown(false);
        this.setOrdersCancelling(this.cancellingOrder!.id, false);
        this.setCancellingOrder(null);
    };

    public onNotValidItemsModalConfirm = async () => {
        await this.completeCreatingOrder(this.notValidItemsOrder!);
        this.setNotValidItemsModalItem(null);
        this.setNotValidItemsModalShown(false);
    };

    public onNotValidItemsModalCancel = async () => {
        await this.onCancelOrder(this.notValidItemsOrder!);
        this.setNotValidItemsModalItem(null);
        this.setNotValidItemsModalShown(false);
    };

    public closeNotValidItemsModal = (): void => {
        this.setNotValidItemsModalShown(false);
    };

    public openNotValidItemsModal = (): void => {
        this.setNotValidItemsModalShown(true);
    };

    private getLink(): string {
        return getStringifiedQueryParams({
            warehouses: this.editFormValues?.warehouses,
            discountPercent: this.editFormValues?.discountPercent,
            ignoreRegionalProhibitions:
                this.editFormValues?.ignoreRegionalProhibitions,
        });
    }

    @computed
    get todayOrders(): NotCompletedOrder[] {
        return this.notCompletedOrders.filter(o => dayjs(o.date).isSame(dayjs(), 'day'));
    }

    @computed
    get upToThisDayOrders(): NotCompletedOrder[] {
        return this.notCompletedOrders.filter(o => !dayjs(o.date).isSame(dayjs(), 'day'));
    }
}

export const OrdersCheckoutStoreContext = React.createContext<null | OrdersCheckoutStore>(null);
