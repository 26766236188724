import React, {useEffect, useMemo, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {NewOrderTableData, Order} from "../../../../../../models/orders/orders-list/order";
import {columnsFactory} from "./agent.data.invoices.new.order-table.columns";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Table} from "antd";
import {ordersListService} from "../../../../../../services/orders/orders-list/orders.list.service";
import {TableRowSelection} from "antd/es/table/interface";

interface AgentDataInvoicesNewOrderTableProps {
    accountId: number;
    onChange?: (values: number[]) => void;
    value?: number[]
}

export const AgentDataInvoicesNewOrderTable: React.FC<AgentDataInvoicesNewOrderTableProps> = ({accountId, onChange, value}) => {

    const t = useTranslate();

    const [orders, setOrders] = useState<NewOrderTableData[]>([]);

    const columns: ColumnsType<NewOrderTableData> = useMemo(() => columnsFactory(orders, t), [orders])

    const [loading, setLoading] = useState<boolean>(false);

    const rowSelection: TableRowSelection<NewOrderTableData> = {
        type: "checkbox",
        selectedRowKeys: value || [],
        onChange: (selectedRowKeys: React.Key[], selectedRows: NewOrderTableData[]) => {
            onChange && onChange(selectedRowKeys as number[]);
        }
    }

    useEffect(() => {
        setLoading(true);
        ordersListService.getAllNewOrders(accountId).then(orders => {
            setOrders(orders.map<NewOrderTableData>(v => {
                let doc = null;
                const docs = v.documents?.proforma;
                if (docs) {
                    doc = docs[0];
                }
                return {
                    ...v,
                    receiverDetails: (() => {
                        const fields = [];
                        if (v.receiver.name) {
                            fields.push(v.receiver.name)
                        }
                        fields.push(v.receiver.companyName, v.receiver.address)
                        return fields.join(", ")
                    })(),
                    fpes: "FPES-0" + (doc?.docNumber.split("-")[1] || ""),
                    joinedComments: v.comments.join(", ")
                }
            }));
            setLoading(false);
            onChange && onChange(orders.map(o => o.id))
        });
    }, [accountId])

    return (
        <>
            <Table
                loading={loading}
                columns={columns}
                dataSource={orders}
                pagination={false}
                rowKey={"id"}
                rowSelection={rowSelection}
            />
        </>
    );
}