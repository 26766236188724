import React from "react";
import {Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/fontawesome-svg-core";

interface TaskAcceptStatusProps {
    status: boolean;
}

const colorMap: Record<number, [string, IconName]> = {
    0: ["yellow", "spinner"],
    1: ["green", "check"]
}

export const TaskAcceptStatus: React.FC<TaskAcceptStatusProps> = ({status}) => {

    const [color, icon] = colorMap[status ? 1 : 0];

    return (
        <Tag
            color={color}
            icon={<FontAwesomeIcon icon={["fas", icon]} />}
        >
            &nbsp;{status ? 'Accepted' : 'Awaiting acceptance'}
        </Tag>
    )
}
