import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faLanguage,
    faCoffee,
    faGlobeAmericas,
    faTimes,
    faPencilAlt,
    faCheck,
    faUser,
    faShoppingBasket,
    faChartLine,
    faCubes,
    faCube,
    faUsers,
    faPowerOff,
    faPlusCircle,
    faChevronLeft,
    faChevronRight,
    faBars,
    faAddressCard,
    faList,
    faCalculator,
    faPlus,
    faFilter,
    faLink,
    faArrowUp,
    faArrowDown,
    faEye,
    faEyeSlash,
    faDice,
    faCog,
    faCalendarAlt,
    faStore,
    faFileUpload,
    faEnvelope,
    faTruck,
    faUpload,
    faArrowRight,
    faExclamationTriangle,
    faSyncAlt,
    faFilePdf,
    faFileCode,
    faEllipsisV,
    faPrint,
    faBook,
    faItalic,
    faBold,
    faListOl,
    faListUl,
    faCheckCircle,
    faQuoteLeft,
    faCode,
    faTable,
    faInfo,
    faQuestion,
    faExclamation,
    faHSquare,
    faSadCry,
    faQuestionCircle,
    faBorderAll,
    faBan,
    faArrowCircleLeft,
    faFileInvoice,
    faCreditCard,
    faTrash,
    faBox,
    faPallet,
    faDownload,
    faSave,
    faBalanceScale,
    faReply,
    faClock,
    faEdit,
    faRunning,
    faShip,
    faMoneyCheckAlt,
    faBalanceScaleLeft,
    faNotEqual,
    faHardHat,
    faMinus,
    faClipboardList,
    faCamera,
    faStickyNote,
    faBuilding,
    faPeopleArrows,
    faBoxTissue,
    faTasks,
    faSpinner,
    faWindowClose,
    faCouch,
    faBolt,
    faUserTimes,
    faUserPlus,
    faEuroSign,
    faHome,
    faLifeRing,
    faArchive,
    faWarehouse,
    faChair,
    faArrowLeft,
    faPercent, faIndustry
} from "@fortawesome/free-solid-svg-icons";

import {
    faFile,
} from "@fortawesome/free-regular-svg-icons";

library.add(
    faLanguage,
    faCoffee,
    faGlobeAmericas,
    faTimes,
    faPencilAlt,
    faCheck,
    faUser,
    faShoppingBasket,
    faChartLine,
    faCubes,
    faCube,
    faUsers,
    faPowerOff,
    faPlusCircle,
    faChevronLeft,
    faChevronRight,
    faBars,
    faAddressCard,
    faList,
    faCalculator,
    faPlus,
    faFilter,
    faLink,
    faArrowUp,
    faArrowDown,
    faEye,
    faEyeSlash,
    faDice,
    faCog,
    faCalendarAlt,
    faStore,
    faFileUpload,
    faEnvelope,
    faTruck,
    faUpload,
    faArrowLeft,
    faArrowRight,
    faExclamationTriangle,
    faSyncAlt,
    faFile,
    faFilePdf,
    faFileCode,
    faEllipsisV,
    faPrint,
    faBook,
    faItalic,
    faBold,
    faListOl,
    faListUl,
    faCheckCircle,
    faQuoteLeft,
    faCode,
    faTable,
    faInfo,
    faQuestion,
    faExclamation,
    faHSquare,
    faSadCry,
    faQuestionCircle,
    faBorderAll,
    faBan,
    faArrowCircleLeft,
    faFileInvoice,
    faCreditCard,
    faTrash,
    faBox,
    faPallet,
    faDownload,
    faSave,
    faBalanceScale,
    faReply,
    faClock,
    faEdit,
    faRunning,
    faShip,
    faMoneyCheckAlt,
    faBalanceScaleLeft,
    faNotEqual,
    faHardHat,
    faMinus,
    faClipboardList,
    faCamera,
    faStickyNote,
    faPeopleArrows,
    faBoxTissue,
    faTasks,
    faSpinner,
    faWindowClose,
    faCouch,
    faBolt,
    faUserTimes,
    faUserPlus,
    faBuilding,
    faEuroSign,
    faCouch,
    faLifeRing,
    faArchive,
    faWarehouse,
    faChair,
    faPercent,
    faIndustry
);
