import {SystemUser} from "../users/user/system.user";
import {Department} from "../departments/department";
import {DepartmentRole} from "../departments/roles";
import {PlanerTag} from "../departments/planer_tags";

export enum PlannerTaskPriority
{
    LOW = 'low',
    MIDDLE = 'middle',
    HIGH = 'high',
    EMERGENCY = 'emergency'
}

export enum PlannerTaskType
{
    TASK = 'task',
    ISSUE = 'issue'
}

export enum PlannerTaskSystemStatus
{
     PENDING = 'pending',
     TODO = 'todo',
     IN_PROGRESS = 'in_progress',
     ON_REVIEW = 'on_review',
     DONE = 'done',
     CANCELLED = 'cancelled'
}

export interface PlannerTaskStatus
{
    name: string,
    color: string;
}

export interface PlannerTaskGroup
{
    name: string,
    color: string;
    department: Department;
    statuses: PlannerTaskStatus[]
}

export interface PlannerTask
{
    id: number;
    priority: PlannerTaskPriority;
    name: string;
    description: string;
    author: SystemUser;
    group?: PlannerTaskGroup;
    status: PlannerTaskSystemStatus;
    accepted: boolean;
    type: PlannerTaskType;
    departments: Department[];
    assignees: PlannerTaskAssignee[];
    tags: PlanerTag[];
    plannedStartDate: string;
    plannedEndDate: string;
    realStartDate: string;
    realEndDate: string;
}

export interface PlannerTaskAssignee
{
    id: number;
    role: DepartmentRole;
    user: SystemUser;
    department: Department;
}

export interface PlannerTaskComment
{
    content: string;
    author: SystemUser;
    createdAt: string;
}

export interface PlannerTaskFile
{
    id: number;
    hash: string;
    filename: string;
}