import {AxiosInstance} from "axios";
import API from "../../utils/api";

export class AccountService {
    api: AxiosInstance = API;

    updateGeneral(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/general", data).then(x => x.data);
    }

    updateAgent(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/agent", data).then(x => x.data);
    }

    updatePrice(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/price", data).then(x => x.data);
    }

    updateWarehouses(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/warehouses", data).then(x => x.data);
    }

    updateDocuments(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/documents", data).then(x => x.data);
    }

    updateShipments(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/shipments", data).then(x => x.data);
    }

    updateAddresses(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/addresses", data).then(x => x.data);
    }
    updateWebSite(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/website", data).then(x => x.data);
    }

    updateTecOrder(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/tecorder", data).then(x => x.data);
    }

    activate(accountId: number, data: any): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/activate", data).then(x => x.data);
    }

    cancel(accountId: number): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/cancel").then(x => x.data);
    }

    reactivate(accountId: number): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/reactivate").then(x => x.data);
    }

    deactivate(accountId: number): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/deactivate").then(x => x.data);
    }

    suspend(accountId: number): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/suspend").then(x => x.data);
    }

    unsuspend(accountId: number): Promise<any> {
        return this.api.post("accounts/wholesale/" + accountId + "/unsuspend").then(x => x.data);
    }
    create(data: any): Promise<{ number: string }> {
        return this.api.post("accounts/wholesale", data).then(x => x.data);
    }
}

export const accountService = new AccountService();
