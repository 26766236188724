import {DepartmentRole} from "./roles";
import {PlanerTag} from "./planer_tags";

export interface DepartmentFlatten {
    id: number;
    name: string;
    department: Department;
}

export enum DepartmentType {
    RETAIL      = 'retail',
    WHOLESALE   = 'wholesale',
    ACCOUNTING  = 'accounting',
    ANALYTIC    = 'analytic',
    MANAGEMENT  = 'management',
    WAREHOUSE   = 'warehouse',
    DEVELOPMENT = 'development',
    MAINTENANCE = 'maintenance'
}

export interface Department {
    id: number;
    name: string;
    type: DepartmentType;
    children: Department[]
}

export interface DepartmentWithParent {
    id: number;
    name: string;
    roles: DepartmentRole[]
    parent: DepartmentFlatten | null;
}

export interface DepartmentExpanded extends Department {
    roles: DepartmentRole[],
    plannerTags: PlanerTag[],
    parent: Department | null
}
