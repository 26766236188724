import {PlannerTask} from "../../../models/planner/planner";
import {SystemUser} from "../../../models/users/user/system.user";

export interface ADepartment {
    id: number;
    name: string;
    assignees: SystemUser[];
}

export const getDepartmentWithAssignees = (task: PlannerTask): ADepartment[] => {
    const assigneesMap = task.assignees.reduce((acc, assignee) => {
        (acc[assignee.department.id] ||= []).push(assignee.user);
        return acc;
    }, {} as Record<number, SystemUser[]>);

    return task.departments.map(department => ({
        id: department.id,
        name: department.name,
        assignees: assigneesMap[department.id] || []
    }));
}