import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {City} from "../../../../../../models/countries/country";
import {branchesService} from "../../../../../../services/branches/branches.service";

export interface WidgetProps
{
    countryCode?: string;
    value?: any;
    onChange?: (value: any) => void;
}
export const UiInputWidgetCity: React.FC<WidgetProps> = ({countryCode, value, onChange}) => {

    const [disabled, setDisabled] = useState<boolean>(false);
    const [noValues, setNoValues] = useState<boolean>(false);
    const [values, setValues] = useState<City[]>([]);

    useEffect(() => {
        setDisabled(true);
        if (countryCode) {
            try {
                branchesService.getCities(countryCode).then(values => {
                    setValues(values)
                    setNoValues(values.length === 0)
                });
            } finally {
                setDisabled(false);
            }
        }
    }, [countryCode])

    if (noValues) {
        return null;
    }

    return (
        <Select
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            disabled={disabled}
        >
            {values.map(value => (
                <Select.Option value={value.id}>{value.name}</Select.Option>
            ))}
        </Select>
    )
}