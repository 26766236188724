import React from "react";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import {DeclarationPart} from "../../../../../declaration.upload.model";
import {useTranslate} from "../../../../../../../../hooks/translate.hook";
import {declarationService} from "../../../../../../../../services/declarations/declarations.services";

interface EditPartModalProps {
    onClose: () => void;
    onComplete: () => void;
    part: DeclarationPart;
}

export const EditPartModal: React.FC<EditPartModalProps> = ({onClose, onComplete, part}) => {

    const t = useTranslate();

    const [form] = useForm();

    const reloadShortAndTarik = () => {
        const value = form.getFieldValue('vedCode');
        if (value && value.length > 5) {
            form.setFieldsValue({
                vedCodeShort: value.slice(0, -2),
                tarik: value.slice(-2)
            });
        } else {
            form.setFieldsValue({
                vedCodeShort: '',
                tarik: ''
            });
        }
    }

    return (
        <Modal
            width={"400px"}
            visible={true}
            title={"Ved Code"}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
            okText={t("SAVE")}
        >
            <Form
                form={form}
                initialValues={{
                    vedCode: part.vedCode,
                    vedCodeShort: part.vedCodeShort,
                    tarik: part.tarik,
                    lisa: part.lisa,
                    rahvuslik: part.rahvuslik
                }}
                layout="vertical"
                onFinish={() =>
                    form.validateFields().then(async (values) => {
                        await declarationService.changePartVedCode(part.id, {
                            vedCode: values.vedCode,
                            lisa: values.lisa,
                            rahvuslik: values.rahvuslik
                        });
                        onComplete();
                        onClose();
                    })
                }
            >
                <Row gutter={[5, 5]}>
                    <Col span={24}>
                        <Form.Item
                            name="vedCode"
                            label={"Ved Code"}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input onChange={() => reloadShortAndTarik()} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="vedCodeShort"
                            label={"Ved Code Short"}
                        >
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="tarik"
                            label={"Tarik"}
                        >
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="lisa"
                            label={"Lisa"}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="rahvuslik"
                            label={"Rahvuslik"}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}