import React, {useEffect, useState} from "react";
import {Input, Select} from "antd";
import {CityArea} from "../../../../../../models/countries/country";
import {countriesService} from "../../../../../../services/countries/countries.service";
import {WidgetProps} from "../widget";
import {useTranslate} from "../../../../../../hooks/translate.hook";

export interface Props extends WidgetProps
{
    countryCode?: string;
    cityName?: string;
    disabled: boolean;
}
export const UiInputWidgetCityArea: React.FC<Props> = ({countryCode, cityName, value, onChange, disabled}) => {

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [values, setValues] = useState<CityArea[]>([]);

    const updateValue = (value: string) => {
        if (value === "") {
            setValues([]);
        }
        onChange && onChange(value);
    }

    useEffect(() => {
        setLoading(true);
        if (cityName && countryCode) {
            try {
                countriesService.getCityAreasForCity(countryCode, cityName).then(values => {
                    setValues(values)
                });
            } finally {
                setLoading(false);
            }
        }
    }, [cityName, countryCode])

    if (values.length === 0) {
        return (
            <Input
                disabled={disabled || loading}
                type="text"
                placeholder={t("CITY_AREA")}
                onChange={e => updateValue(e.target.value)}
                value={value as any}
            />
        )
    }

    return (
        <Select
            onChange={updateValue}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            disabled={disabled || loading}
        >
            {values.map(value => (
                <Select.Option value={value.name}>{value.name}</Select.Option>
            ))}
            <Select.Option value={""}>{t("OTHER")}</Select.Option>
        </Select>
    )
}