import React, {useState} from "react";
import {observer} from "mobx-react";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Button, Checkbox, Col, Form, Input, Row, Typography} from "antd";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";

export const AgentDataAddressesOptions: React.FC<ComponentPropsFromRoute> = observer(({name}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updateAddresses(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    const defaultValues = {
        allowedAddressManagement: account.allowedAddressManagement
    }

    return <Form
        size={"middle"}
        form={form}
        layout="vertical"
        name={"client_form"}
        initialValues={defaultValues}
        onFinish={async (values) => {
            await form.validateFields();
            await submit(values)
        }}
    >
        <Typography.Title level={3}>{t("ADDRESSES.TITLE")}</Typography.Title>
        <Row gutter={[30, 0]} align={"top"}>
            <Col span={12}>
                <Row gutter={[30, 0]} align={"top"}>
                    <Col span={24}>
                        <Form.Item name={["allowedAddressManagement"]} label={t("ADDRESSES.ALLOW_MANAGEMENT")} valuePropName={"checked"}>
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row gutter={[30, 0]} align={"top"}>
            <Col span={24}>
                <Button disabled={loading} type={"primary"} onClick={() => form.submit()}>{t("SAVE")}</Button>
            </Col>
        </Row>
    </Form>
});
