import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useTranslate} from "../../../hooks/translate.hook";
import {Link, useParams} from "react-router-dom";
import {productionService} from "../../../services/production/production.service";
import {Application} from "../../../models/production/application.model";
import {LoadingSpin} from "../../common/loading-spin/loading.spin";
import {PageHeader} from "@ant-design/pro-components";
import {Button, Descriptions, Space, Tag, Typography} from "antd";
import Date from "../../date/date";
import {useNavigate} from "react-router";
import {notifications} from "../../../services/notifications/notifications";
import {ApplicationsStatus} from "../common/status/application.status";

export const ProductionRequestView: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const t = useTranslate();
    const navigate = useNavigate();

    const { id } = useParams<{ id: string }>();

    const [application, setApplication] = useState<Application | null>(null);

    const reload = async () => {
        setApplication(null);
        await productionService.getApplication(parseInt(id!)).then(app => setApplication(app));
    }

    useEffect(() => {
        reload();
    }, [id]);

    const accept = async () => {
        await productionService.accept(parseInt(id!));
        await reload();
        notifications.successfully();
    }

    const reject = async () => {
        await productionService.reject(parseInt(id!));
        await reload();
        notifications.successfully();
    }

    const markAsReceived = async () => {
        await productionService.markAsReceived(parseInt(id!));
        await reload();
        notifications.successfully();
    }

    if (!application) {
        return <LoadingSpin />
    }

    return <>

        <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            title={
                <Typography.Title level={1} style={{ margin: 0 }}>
                    {t("PRODUCTION_REQUEST.VIEW.TITLE")} #{application.id}
                </Typography.Title>
            }
            onBack={() => {
                navigate("/production");
            }}
            extra={<ApplicationsStatus status={application.status} large />}
        />

        <Space direction={"vertical"} size={[50, 50]} style={{marginTop: 20}}>

            <div className="data-descriptions-with-edit-wrapper">
                <div className="data-content-wrapper">
                    <Descriptions
                        className="data-content-wrapper-descriptions"
                        size={"small"}
                        layout={"vertical"}
                        contentStyle={{ fontSize: "17px" }}
                        labelStyle={{ color: "#858585" }}
                        column={{
                            xxl: 4,
                            xl: 4,
                            lg: 4,
                            md: 3,
                            sm: 2,
                            xs: 1,
                        }}
                        items={[
                            {label: t("CLIENT.NAME"), children: <Link className="link" to={"/clients/" + application.account.agent.number}>{application.account.agent.name}</Link>},
                            {label: t("USER.TITLE"), children: <>{application.user.firstName} {application.user.lastName}</>},
                            {label: t("DATE"), children: <Date dateString={application.createdAt} showTime />}
                        ]}
                    />
                </div>
            </div>

            <Descriptions
                layout={"horizontal"}
                bordered
                column={1}
                size={"middle"}
                title={"Application Data"}
                labelStyle={{width: '20%'}}
                items={[
                    {label: t("TYPE"),                                         children: t("PRODUCTION_REQUEST.FIELD.TYPE." + application.type.toUpperCase())},
                    {label: t("PRODUCTION_REQUEST.FIELD.OE_NUMBER.TITLE"),     children: application.OENumber},
                    {label: t("PRODUCTION_REQUEST.FIELD.DESCRIPTION.TITLE"),   children: application.description},
                    ...(application.type === 'offer' ? [{label: t("PRODUCTION_REQUEST.FIELD.JUSTIFICATION.TITLE"), children: application.justification}] : []),
                    {label: t("PRODUCTION_REQUEST.FIELD.QTY.TITLE"),           children: application.qty},
                    {label: t("PRODUCTION_REQUEST.FIELD.PRICE.TITLE"),         children: application.price},
                ]}
            />

            {application.type === 'offer' ? (
                <Space direction={"horizontal"}>
                    <Button type={"primary"} onClick={markAsReceived} disabled={application.status === 'feedback_received'}>Make as received</Button>
                </Space>
            ) : null}

            {application.type === 'order' ? (
                <Space direction={"horizontal"}>
                    <Button type={"primary"} onClick={accept} disabled={application.status === 'accepted'}>Accept</Button>
                    <Button type={"primary"} danger onClick={reject}  disabled={application.status === 'rejected'}>Reject</Button>
                </Space>
            ) : null}

        </Space>

    </>
}