import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {City, CityArea, Country} from "../../models/countries/country";

export class CountriesService {
    api: AxiosInstance = API;

    getAll(): Promise<Country[]> {
        return this.api.get<Country[]>('countries').then(x => x.data);
    }

    getCitiesForCountry(countryCode: string): Promise<City[]> {
        return this.api.get<City[]>('countries/' + countryCode + '/city').then(x => x.data);
    }

    getCityAreasForCity(countryCode: string, cityName: string): Promise<CityArea[]> {
        return this.api.get<CityArea[]>('countries/' + countryCode + '/city/area', {'params': {'cityName': cityName}}).then(x => x.data);
    }
}

export const countriesService = new CountriesService();