import { AxiosInstance } from "axios";
import API from "../../utils/api";
import {
    Claim,
    ClaimAddComment, ClaimApplyDiscountAction,
    ClaimBatchAction,
    ClaimComment,
    ClaimCreditGroup,
    ClaimItemReject, ClaimItemsReject,
    ClaimSetWarehouseAction,
    ClaimType,
    CreateClaim,
    CreateClaimCredit,
    CreateClaimCreditWithItems,
    CreateClaimItemWithInvoice,
    EditClaimItem
} from "../../models/claims/claims";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {CreditInvoice} from "../../models/documents/invoices/credit-invoice/credit.invoice";
import {SearchedMergedInvoice} from "../../models/documents/invoices/merged-invoice/merged.invoice";
import {SplitItemListItem} from "../../components/claims/actions/split/model";
import {stringify} from "query-string";

export class ClaimsService {
    private api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<Claim>> {
        return this.api
            .get<PaginatedResult<Claim>>("claims", {params: request.toParams()})
            .then((x) => x.data);
    }

    getAllByAccount(request: PaginatedRequest, accountId: number): Promise<PaginatedResult<Claim>> {
        request.addFilter("account", accountId.toString());
        return this.getAll(request);
    }

    getAllReclamationsByArticle(request: PaginatedRequest, articleId: number): Promise<PaginatedResult<Claim>> {
        request.addFilter("article", articleId.toString());
        request.addFilter("type", ClaimType.RECLAMATION)
        return this.getAll(request);
    }

    getClaim(id: number): Promise<Claim> {
        return this.api.get<Claim>("claims/" + id).then((x) => x.data);
    }

    getClaimComments(id: number): Promise<ClaimComment[]> {
        return this.api
            .get<ClaimComment[]>("claims/" + id + "/comments")
            .then((x) => x.data);
    }

    addComment(id: number, comment: ClaimAddComment): Promise<ClaimComment> {
        return this.api
            .post<ClaimComment>("claims/" + id + "/comments", comment)
            .then((x) => x.data);
    }

    removeComment(id: number): Promise<void> {
        return this.api.delete("claims/comments/" + id);
    }

    createClaimWithAccount(accountId: number, request: CreateClaim): Promise<Claim> {

        const items = request.items.map(item => { // transform images

            const newItem: any = {
                ...item,
                data: {...item.data}
            };

            if (item.type === "known") {
                const data = item.data as CreateClaimItemWithInvoice;
                newItem.data.invoice = data.invoice?.id;
                newItem.data.item = data.item?.id;
            }

            if (item.data?.images) {
                newItem.data.images = item.data.images.map(i => parseInt(i.uid));
            }

            return newItem;
        })

        return this.api
            .post<Claim>("accounts/" + accountId + "/claims", {items, type: request.type})
            .then((x) => x.data);
    }

    public async searchMergedInvoices(accountId: number, articleId: number): Promise<SearchedMergedInvoice[]> {
        return (await this.api.get<SearchedMergedInvoice[]>("accounts/" + accountId + "/claims/merged-invoices?article=" + articleId)).data;
    }

    complete(id: number): Promise<Claim> {
        return this.api
            .post<Claim>("claims/" + id + "/complete")
            .then((x) => x.data);
    }

    updateItem(id: number, request: EditClaimItem): Promise<any> {
        return this.api
            .post<void>("claims/items/" + id, request);
    }

    acceptItem(id: number): Promise<any> {
        return this.api
            .post<void>("claims/items/" + id + "/accept");
    }

    acceptItems(id: number, dto: ClaimBatchAction): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/items/accept", dto);
    }

    acceptAllItems(id: number): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/accept");
    }

    rejectItem(id: number, request: ClaimItemReject): Promise<any> {
        return this.api
            .post<void>("claims/items/" + id + "/reject", request);
    }

    rejectItems(id: number, request: ClaimItemsReject): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/items/reject", request);
    }

    rejectAllItems(id: number, request: ClaimItemReject): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/reject", request);
    }

    needClarificationItem(id: number, request: ClaimItemReject): Promise<any> {
        return this.api
            .post<void>("claims/items/" + id + "/request-clarification", request);
    }

    needClarificationAllItems(id: number, request: ClaimItemReject): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/request-clarification", request);
    }

    guessInvoicesForUnknowns(id: number, values: any): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/guess-invoices", values);
    }

    merge(id: number, dto: ClaimBatchAction): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/merge", dto);
    }

    split(id: number, itemId: number, values: SplitItemListItem[]): Promise<any> {
        const newValues: any = [...values];
        newValues.forEach((newValue: any, idx: number) => {
            newValues[idx] = {...newValue, price: newValue.price.value}
        })
        return this.api
            .post<void>("claims/" + id + "/items/" + itemId + "/split", {items: newValues});
    }

    batchSetWarehouse(id: number, dto: ClaimSetWarehouseAction): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/set-warehouse", dto);
    }

    batchApplyDiscount(id: number, dto: ClaimApplyDiscountAction): Promise<any> {
        return this.api
            .post<void>("claims/" + id + "/apply-discount", dto);
    }

    removeFile(id: number): Promise<any> {
        return this.api
            .delete<void>("claim/attachment/" + id);
    }

    getCreditGroups(claimId: number): Promise<ClaimCreditGroup[]> {
        return this.api.get<ClaimCreditGroup[]>("claims/" + claimId + "/credit/groups").then(x => x.data);
    }

    getCreditGroupsWithItems(claimId: number, items: number[]): Promise<ClaimCreditGroup[]> {
        return this.api.get<ClaimCreditGroup[]>("claims/" + claimId + "/credit/groups-with-items",
            {params: {items: items}, paramsSerializer: p => stringify(p, {arrayFormat: "bracket"})}).then(x => x.data);
    }

    createCredit(claimId: number, dto: CreateClaimCredit): Promise<CreditInvoice> {
        return this.api.post<CreditInvoice>("claims/" + claimId + "/credit", dto).then(x => x.data);
    }

    createCreditWithItems(claimId: number, dto: CreateClaimCreditWithItems): Promise<CreditInvoice> {
        return this.api.post<CreditInvoice>("claims/" + claimId + "/credit-with-items", dto).then(x => x.data);
    }
}

export const claimService = new ClaimsService();