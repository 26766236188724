import {Translate} from "../../../../../hooks/translate.hook";
import React from "react";
import {Link} from "react-router-dom";
import {OrderStatus} from "../../../../../stores/common/common.store";
import {OrdersStatus} from "../../../../orders/status/order.status";
import {Warehouse} from "../../../../../models/warehouses/warehouse";
import {Amount} from "../../../../../models/prices/price";
import {displayPrice} from "../../../../../models/prices/price.helper";
import Date from "../../../../date/date";
import {Order} from "../../../../../models/orders/orders-list/order";
import {ColumnType} from "antd/es/table/interface";
import {Agent} from "../../../../../models/accounts/customer-account/wholesale-account/wholesale.account";

export const columnsFactory = (t: Translate): ColumnType<Order>[] => [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: "160px",
        render: (id: string) => (
            <Link className="link" to={"/orders/" + id}>
                {id}
            </Link>
        ),
    },
    {
        title: t("ORDERS.ALT_NUMBER"),
        dataIndex: "clientOrderId",
        key: "orders_clientOrderId",
        width: "160px"
    },
    {
        title: t("CLIENT.NAME"),
        dataIndex: "agent",
        key: "agent",
        width: "275px",
        render: (agent: Agent) => <Link to={"/clients/" + agent.number}>{agent.name}</Link>
    },
    {
        title: t("ORDERS.STATUS.TITLE"),
        dataIndex: "status",
        key: "orders_status",
        render: (status: OrderStatus) => <OrdersStatus status={status} />,
        width: "220px"
    },
    {
        title: t("GRAND_TOTAL_PRICE"),
        dataIndex: "grandTotal",
        key: "orders_grandTotal",
        render: (total: Amount) => <>{displayPrice(total)}</>,
    },
    {
        title: t("CREATED_AT"),
        dataIndex: "createdAt",
        key: "orders_createdAt",
        render: (date: string) => <Date dateString={date} showTime />,
    }
];