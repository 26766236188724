import React from "react";
import {Select} from "antd";
import {WidgetProps} from "../../../../../../../common/cards-view/model/model";

export const TaskAcceptStatusWidget: React.FC<WidgetProps> = ({onChange, value, placeholder}) => {

    const values = [
        {
            name: 'Accepted',
            value: '1'
        },
        {
            name: 'Awaiting acceptance',
            value: '0'
        }
    ]

    return (
        <Select
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            placeholder={placeholder}
            maxTagCount={1}
            variant={"filled"}
        >
            {values.map(v =>
                <Select.Option key={v.value} value={v.value}>{v.name}</Select.Option>)}
        </Select>
    )
}