import React, {useMemo} from "react";
import {columnsFactory} from "./request.list.columns";
import {ColumnsType} from "antd/es/table";
import i18n from "i18next";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {ColumnsConfig, convertTableColumnsToColumnConfigs} from "../../../utils/table";
import {FilterDataHandler} from "../../../models/filter/filter.data.handler";
import FilteredTable from "../../common/filtered-table/filtered.table";
import {Application, ApplicationStatus} from "../../../models/production/application.model";
import {productionService} from "../../../services/production/production.service";
import {FieldGroupConfig} from "../../../models/filter/filter.group";
import {filterGroupsFactory} from "./request.filter-groups";
import {useTranslate} from "../../../hooks/translate.hook";
import {FilterButtonConfig} from "../../../models/filtered-table/filtered.table.props";
import {ClaimFilterButtonsTypes} from "../../claims/list/claim.list";

export const ProductionRequestList: React.FC<ComponentPropsFromRoute> = ({name, requiredConfigs}) => {

    const t = useTranslate();

    const columns: ColumnsType<any> = useMemo(() => columnsFactory(t), [i18n.language]);
    const columnsConfigs: ColumnsConfig = useMemo(() => convertTableColumnsToColumnConfigs<any>(columns), [i18n.language])
    const fcgConfigs: FieldGroupConfig[] = useMemo(() => filterGroupsFactory(t), []);
    const dataHandler: FilterDataHandler<Application> = useMemo(() => new FilterDataHandler(request => productionService.getApplications(request, requiredConfigs!.type)), [requiredConfigs]);

    const filterButtonConfigs: FilterButtonConfig<ClaimFilterButtonsTypes>[] = [
        {
            type: ClaimFilterButtonsTypes.NOT_PROCESSED_CLAIMS,
            text: "CLAIMS.NOT_PROCESSED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: [ApplicationStatus.PENDING, ApplicationStatus.SUBMITTED].join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: ClaimFilterButtonsTypes.PROCESSED_CLAIMS,
            text: "CLAIMS.PROCESSED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: [ApplicationStatus.ACCEPTED, ApplicationStatus.REJECTED, ApplicationStatus.FEEDBACK_RECEIVED].join(","),
                        },
                    ],
                },
            ],
        }
    ];

    return (
        <FilteredTable
            columns={columns}
            columnsConfigs={{columns: columnsConfigs, pageKey: name!}}
            dataHandler={dataHandler}
            filterGroups={fcgConfigs}
            filterButtons={filterButtonConfigs}
        />
    );
}
