import {TFunction} from "react-i18next";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Application, ApplicationStatus} from "../../../models/production/application.model";
import Date from "../../date/date";
import {Link} from "react-router-dom";
import {Tag} from "antd";
import {ApplicationsStatus} from "../common/status/application.status";

export const columnsFactory = (t: TFunction<"translation">): ColumnsType<Application> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: id => <Link to={"/production/application/" + id}>#{id}</Link>
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (item: ApplicationStatus) => <ApplicationsStatus status={item} />
        },
        {
            title: t("CLIENT.NAME"),
            dataIndex: "account",
            key: "account",
            width: "255px",
            render: ({ agent }) => <Link to={"/clients/" + agent.number}>{agent.name}</Link>,
        },
        {
            title: t("PRODUCTION_REQUEST.FIELD.OE_NUMBER.TITLE"),
            dataIndex: "OENumber",
            key: "OENumber",
            render: (item: string) => item
        },
        {
            title: t("PRODUCTION_REQUEST.FIELD.QTY.TITLE"),
            dataIndex: "qty",
            key: "qty",
            render: (item: string) => item
        },
        {
            title: t("PRODUCTION_REQUEST.FIELD.PRICE.TITLE"),
            dataIndex: "price",
            key: "price",
            render: (item: string) => item
        },
        {
            title: t("DATE"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item: string) => <Date dateString={item} showTime />
        }
    ];
}