import API from "../../../utils/api";
import {AxiosInstance, AxiosResponse} from "axios";
import {OrdersCart, OrdersCartQuery} from "../../../models/orders/orders-cart/orders-create/orders.cart";
import {OrdersCartRequest} from "../../../models/orders/orders-cart/orders-create/orders.cart.request";
import {
    AddToCartRequest,
} from "../../../models/orders/orders-cart/orders-create/orders.create.add.values";
import {ImportResponse} from "../../../models/import/import.response";
import {OrdersCartRequestQuery} from "../../../models/orders/orders-cart/orders-create/orders.cart.request.query";
import {OrdersCreateResult} from "../../../models/orders/orders-cart/orders-create/orders.create.response";
import {
    OrderCreateType
} from "../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/order.create.type";

export class OrdersCreateService {
    private api: AxiosInstance = API;

    getCart(id: number, query: OrdersCartQuery): Promise<OrdersCart> {
        return this.api.get<OrdersCart>(
            "accounts/" + id + "/cart",
            {
                params: query
            }
        ).then(x => x.data);
    }

    addToCart(id: number, request: AddToCartRequest): Promise<void> {
        return this.api.post(
            "accounts/" + id + "/cart",
            request
        )
    }

    updateCart(accountId: number, articleId: number | string, request: OrdersCartRequest): Promise<void> {
        return this.api.post(
            "accounts/" + accountId + "/cart/" + articleId,
            request
        );
    }

    removeCartItem(accountId: number, type: string, articleId: number | string): Promise<void> {
        return this.api.delete("accounts/" + accountId + "/cart/" + type +  "/" + articleId);
    }

    removeCart(accountId: number): Promise<void> {
        return this.api.delete("accounts/" + accountId + "/cart")
    }

    importFile(type: OrderCreateType, accountId: number, file: File, onProgress: (percent: number) => void): Promise<AxiosResponse<ImportResponse>> {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);
        return this.api.post<AxiosResponse<ImportResponse>>("accounts/" + accountId + "/cart/import", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: event => onProgress((event.loaded / event.total) * 100)
        }).then(x => x.data);
    }

    createOrder(accountId: number, request: OrdersCartRequestQuery): Promise<OrdersCreateResult> {
        return this.api.post<OrdersCreateResult>("accounts/" + accountId + "/orders", request).then(x => x.data);
    }

    moveToMissingItems(account: number, articles: number[]) {
        return this.api.post("accounts/" + account + "/cart/missing-items/move", {articles});
    }
}

export const ordersCreateService = new OrdersCreateService();
