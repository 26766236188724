import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {Application} from "../../models/production/application.model";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";

export class ProductionService {
    private api: AxiosInstance = API;

    getApplications(request: PaginatedRequest, type: string): Promise<PaginatedResult<Application>> {
        request.addFilter('type', type);
        return this.api
            .get<PaginatedResult<Application>>("production/application", {params: request.toParams()})
            .then((x) => x.data);
    }

    getApplication(id: number): Promise<Application> {
        return this.api
            .get<Application>("production/application/" + id)
            .then((x) => x.data);
    }

    accept(id: number): Promise<void> {
        return this.api
            .post<void>("production/application/" + id + "/accept")
            .then((x) => x.data);
    }

    reject(id: number): Promise<void> {
        return this.api
            .post<void>("production/application/" + id + "/reject")
            .then((x) => x.data);
    }

    markAsReceived(id: number): Promise<void> {
        return this.api
            .post<void>("production/application/" + id + "/make-as-received")
            .then((x) => x.data);
    }
}

export const productionService = new ProductionService();
