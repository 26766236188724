import React, {useState} from "react";
import {Alert, Col, Form, Input, Modal, Row, Space} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Claim, ClaimItem} from "../../../../models/claims/claims";
import {claimService} from "../../../../services/claims/claims.service";

interface Props {
    claim: Claim;
    items: ClaimItem[];
    onClose: () => void,
    onCompleted: () => void
}
export const ApplyDiscountModal: React.FC<Props> = ({claim, items, onClose, onCompleted}) => {

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const complete = () => {
        onCompleted();
        onClose();
    };

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("CLAIM.APPLY_DISCOUNT")}
            okText={t("APPLY")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"dispatch_modal"}
                initialValues={{}}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        const dto = {
                            value: parseFloat(values.value),
                            items: items.map(i => i.id)
                        };

                        claimService.batchApplyDiscount(claim.id, dto)
                            .then(complete)
                            .finally(() => setLoading(false));

                        setLoading(true);
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Space direction={"vertical"} size={[20, 20]}>
                            <Alert
                                type={"warning"}
                                description={t("CLAIM.APPLY_DISCOUNT_ALERT")}
                                showIcon
                            />
                            <Form.Item
                                name="value"
                                label={t("DISCOUNT")}
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                            >
                                <Input type={"number"} />
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}