import React, {useMemo} from "react";
import {Divider, Space} from "antd";
import {TaskTag} from "../common/tags/task.tag";
import {TaskStatus} from "../common/status/task.status";
import {TaskPriority} from "../common/priority/task.priority";
import {Link} from "react-router-dom";
import Date from "../../../date/date";
import "./tasks.list.scss"
import {PlannerTask} from "../../../../models/planner/planner";
import {useTranslate} from "../../../../hooks/translate.hook";
import {getDepartmentWithAssignees} from "../../helper/task-assignees.helper";

interface PlannerTasksListCardProps {
    item: PlannerTask;
}

export const PlannerTasksListCard: React.FC<PlannerTasksListCardProps> = ({item}) => {

    const t = useTranslate();
    const assignees = useMemo(() => getDepartmentWithAssignees(item), [item]);

    return (
        <Space className={"planner-task-view"} direction={"vertical"}>
            <div className={"planner-task-view-title"}>
                <Space direction={"horizontal"} size={[5, 0]}>
                    <Link to={"/planner/tasks/" + item.id}>{item.name}</Link>
                </Space>
            </div>
            <div className={"planner-task-view-tags"}>
                <TaskStatus status={item.status} />
                <TaskPriority priority={item.priority} />
                {item.tags.map((tag, key) => <TaskTag tag={tag} key={key} />)}
            </div>
            <div className={"planner-task-view-details"}>
                #{item.id} Created by {item.author.firstName} {item.author.lastName} for&nbsp;
                {assignees.map(d => d.name + (d.assignees.length > 0 ? (' (' + d.assignees.map(a => a.firstName + ' ' + a.lastName).join(', ') + ')') : '')).join(', ')}
                {item.plannedStartDate && item.plannedEndDate ? (
                    <>,&nbsp;
                        {t('PLANNER.TASK.FIELDS.ESTIMATED_START_DATE')}:&nbsp;
                        <Date dateString={item.plannedStartDate} /> -  <Date dateString={item.plannedEndDate} />
                    </>
                ) : null}
            </div>
            <Divider />
        </Space>
    )
}