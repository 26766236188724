import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {Table} from "antd";
import {columnsFactory} from "./codes-list.columns";
import {DeclarationPart, UploadedDeclaration} from "../../../declaration.upload.model";
import {EditPartModal} from "./modal/edit/edit-part.modal";

interface DeclarationCartCodesListProps {
    declaration: UploadedDeclaration,
    reload: () => Promise<any>
}

export const DeclarationCartCodesList: React.FC<DeclarationCartCodesListProps> = ({declaration, reload}) => {

    const t = useTranslate();

    const [editablePart, setEditablePart] = useState<DeclarationPart | null>(null);

    const onEdit = (part: DeclarationPart) => {
        setEditablePart(part);
    }

    const columns = useMemo(() => columnsFactory(declaration, onEdit, t), [])

    return (
        <>
            <Table
                columns={columns}
                dataSource={declaration.parts}
                pagination={false}
                style={{width: '100%'}}
            ></Table>
            {editablePart ? (
                <EditPartModal
                    onClose={() => setEditablePart(null)}
                    onComplete={() => reload()}
                    part={editablePart}
                />
            ) : null}
        </>
    )
}