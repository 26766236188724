import React, { useState } from "react";
import { Modal, Form, Input, Row, Col, Checkbox, Select, Button } from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import { AxiosError } from "axios";
import { ErrorResponseData } from "../../../../models/core/response";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { AgentDataStoreContext } from "../agent.data.store";
import { CustomerAccountAddress } from "../../../../models/accounts/customer-account/address/customer.account.address";
import {UIInputWidgetCountry} from "../../../common/ui/input/widget/country/ui.input.widget.country";
import {UiInputWidgetCity} from "../../../common/ui/input/widget/city/ui.input.widget.city";
import {UiInputWidgetCityArea} from "../../../common/ui/input/widget/city-area/ui.input.widget.city-area";
interface AgentDataAddressesModalProps {
    item?: CustomerAccountAddress | null;
    visible: boolean;
    type?: string;
    onSubmit: (address: CustomerAccountAddress, id: number) => Promise<void>;
    onClose: () => void;
}

export const AgentDataAddressesModal: React.FC<AgentDataAddressesModalProps> = ({
    visible,
    type,
    item,
    onClose,
    onSubmit,
}) => {
    const t = useTranslate();

    const { langStore } = useRequiredStore(RootStoreContext);
    const store = useRequiredStore(AgentDataStoreContext);

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    let defaultValues = item
        ? { ...item, country: item.country.id }
        : { addSameShippingAddress: true, addToOrderComment: false };

    const fillData: React.MouseEventHandler<HTMLElement> = () => {
        const data = {
            companyName: store.account?.agent.businessName,
            vat: store.account?.agent.vat,
            customsCode: store.account?.agent.customsCode,
            country: store.account?.agent.country.id,
            regCode: store.account?.agent.regCode,
        };
        form.setFieldsValue(data);
    };

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={item ? t("ADDRESS_EDIT") : t("ADDRESS_ADD")}
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            style={{ top: 13 }}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Button disabled={loading} style={{ marginBottom: 10 }} onClick={fillData} type="primary">
                {t("CLIENTS.FILL_DATA")}
            </Button>
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={defaultValues}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(item ? values : { ...values, type }, item ? item.id : store.account?.id!)
                            .then(
                                () => {
                                    !item && form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                }
                            )
                            .then(
                                () =>
                                    // onClose(),
                                    (e: any) =>
                                        form.setFields(e.response?.data?.fields)
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="addressType"
                            label={t("ADDRESSES.TYPE.TITLE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                disabled={loading}
                                placeholder={t("ADDRESSES.TYPE")}
                            >
                                {["short", "full"].map((type) => (
                                    <Select.Option key={type} value={type}>
                                        {t("ADDRESSES.TYPE."  + type.toUpperCase())}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("NAME")} />
                        </Form.Item>
                    </Col>
                    {type === "shipping" ? (
                        <Col span={24}>
                            <Form.Item name="addToOrderComment" valuePropName="checked">
                                <Checkbox>{t("ADD_TO_ORDER_COMMENT")}</Checkbox>
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col span={24}>
                        <Form.Item
                            name="companyName"
                            label={t("COMPANY_NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("COMPANY_NAME")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="firstName" label={t("FIRSTNAME")}>
                            <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="lastName" label={t("LASTNAME")}>
                            <Input disabled={loading} type="text" placeholder={t("LASTNAME")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="vat" label={"VAT"}>
                            <Input disabled={loading} type="text" placeholder={"EE00000000"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="customsCode" label={"EORI"}>
                            <Input disabled={loading} type="text" placeholder={"EORI"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="regCode" label={t("REG_NUMBER")}>
                            <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="country"
                            label={t("COUNTRY")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <UIInputWidgetCountry />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="region" label={t("REGION")}>
                            <Input disabled={loading} type="text" placeholder={t("REGION")} />
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.addressType !== curValues.addressType
                        }
                    >
                        {() => (
                            <>
                                {form.getFieldValue('addressType') === 'full' ? (
                                    <>
                                        <Col span={4}>
                                            <Form.Item
                                                name="cityType"
                                                label={t("ADDRESSES.CITY_TYPE")}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("ADDRESSES.CITY_TYPE")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues.country !== curValues.country}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name="city"
                                                        label={t("CITY")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                            },
                                                        ]}
                                                    >
                                                        <UiInputWidgetCity
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue('country')}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name="cityArea"
                                                        label={t("CITY_AREA")}
                                                    >
                                                        <UiInputWidgetCityArea
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue('country')}
                                                            cityName={form.getFieldValue('city')}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="index"
                                                label={t("INDEX")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                    },
                                                ]}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues.country !== curValues.country}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name="city"
                                                        label={t("CITY")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                            },
                                                        ]}
                                                    >
                                                        <UiInputWidgetCity
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue('country')}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name="cityArea"
                                                        label={t("CITY_AREA")}
                                                    >
                                                        <UiInputWidgetCityArea
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue('country')}
                                                            cityName={form.getFieldValue('city')}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="index"
                                                label={t("INDEX")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                    },
                                                ]}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </>
                        )}
                    </Form.Item>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                                prevValues.addressType !== curValues.addressType
                            }
                        >
                            {() => (
                                <Form.Item
                                    name="address"
                                    label={t("ADDRESS")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading || form.getFieldValue('addressType') === 'full'} type="text" placeholder={t("ADDRESS")} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                        prevValues.addressType !== curValues.addressType
                    }
                >
                    {() => form.getFieldValue('addressType') === 'full' ? (
                        <Row gutter={10}>
                            <Col span={4}>
                                <Form.Item
                                    name="complexType"
                                    label={t("ADDRESSES.COMPLEX.TYPE")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.COMPLEX.TYPE")} />
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <Form.Item
                                    name="complexName"
                                    label={t("ADDRESSES.COMPLEX.NAME")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.COMPLEX.NAME")} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="streetType"
                                    label={t("ADDRESSES.STREET.TYPE")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.TYPE")} />
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item
                                    name="streetName"
                                    label={t("ADDRESSES.STREET.NAME")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.NAME")} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="streetNo"
                                    label={t("ADDRESSES.STREET.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.NO")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="blockNo"
                                    label={t("ADDRESSES.BLOCK.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.BLOCK.NO")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="entranceNo"
                                    label={t("ADDRESSES.ENTRANCE.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.ENTRANCE.NO")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="floorNo"
                                    label={t("ADDRESSES.FLOOR.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.FLOOR.NO")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="apartmentNo"
                                    label={t("ADDRESSES.APARTMENT.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.APARTMENT.NO")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                </Form.Item>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label={t("PHONE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("PHONE")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label={t("EMAIL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("EMAIL")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="tecOrderId"
                            label={"TecOrder ID"}
                        >
                            <Input disabled={loading} type="text" placeholder={"TecOrder ID"} />
                        </Form.Item>
                    </Col>
                    {!item && type === "billing" ? (
                        <Col span={12}>
                            <Form.Item
                                valuePropName={"checked"}
                                name="addSameShippingAddress"
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                    },
                                ]}
                            >
                                <Checkbox>{t("ADD_SAME_SHIPPING_ADDRESS")}</Checkbox>
                            </Form.Item>
                        </Col>
                    ) : null}
                </Row>
            </Form>
        </Modal>
    );
};
