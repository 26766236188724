import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Select, Space, Tooltip, Typography} from "antd";
import {AgentNewContext} from "../../agent.new.modal";
import {InvoicingPeriod} from "../../../../../../models/invoicing/invoicing";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../../utils/store";
import {RootStoreContext} from "../../../../../../stores/root/root.store";
import {observer} from "mobx-react";
import {isAllowed} from "../../../../../../utils/helpers";

export const DocumentsStep: React.FC = observer(() => {

    const t = useTranslate();
    const { commonStore, authStore: {attributes} } = useRequiredStore(RootStoreContext);

    const [loading, setLoading] = useState<boolean>(false);

    const {setStep, getValue, setValue, fields} = React.useContext(AgentNewContext);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields(fields);
    }, [fields])

    const defaultValues = {
        documents: getValue('documents')
    }

    return <Space direction="vertical" size={[15, 15]} style={{marginTop: 40}}>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                setValue('documents', values.documents)
                setStep(4)
            }}
        >
            <Typography.Title level={3}>{t("CLIENT.DOCUMENTS.TITLE")}</Typography.Title>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={12}>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={24}>
                            <Form.Item
                                name={["documents", "documentType"]}
                                label={t("DOCUMENT_TYPE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                    disabled={loading}
                                    placeholder={t("DOCUMENT_TYPE")}
                                >
                                    {commonStore.documentTypes.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item.toLowerCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={["documents", "invoiceDocumentType"]}
                                label={t("INVOICE_DOCUMENT_TYPE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                    disabled={loading}
                                    placeholder={t("INVOICE_DOCUMENT_TYPE")}
                                >
                                    {commonStore.invoiceDocumentTypes.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item.toLowerCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={["documents", "emailType"]}
                                label={t("EMAIL_TYPE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp={"children"}
                                    placeholder={t("EMAIL_TYPE")}
                                    disabled={loading}
                                >
                                    <Select.Option value={"default"}>Default</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={["documents", "invoicingPeriod"]}
                                label={t("INVOICING.PERIOD.TITLE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder={t("INVOICING.PERIOD.TITLE")}
                                    disabled={loading}
                                >
                                    {Object.values(InvoicingPeriod).map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {t("INVOICING.PERIOD." + item.toUpperCase())}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={["documents", "allowedMergedInvoices"]} valuePropName="checked">
                                <Checkbox>{t("CLIENT.ALLOWED_MERGED_INVOICES")}</Checkbox>
                            </Form.Item>
                        </Col>
                        {isAllowed(attributes, 'order_backorder') ? (
                            <Col span={24}>
                                <Form.Item name={["useBackorder"]} valuePropName="checked">
                                    <Checkbox>{t("BRANCH.ALLOW_USE_BACKORDER")}</Checkbox>
                                </Form.Item>
                            </Col>
                        ) : null}
                        <Col span={24}>
                            <Form.Item name={["documents", "separateDeliveryOrder"]} valuePropName="checked">
                                <Checkbox>{t("CLIENT.SEPARATE_DELIVERY_ORDER")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={["documents", "combineGoodsAndDeliveryIsProhibited"]} valuePropName="checked">
                                <Checkbox>{t("CLIENT.COMBINE_GOODS_AND_DELIVERY_IS_PROHIBITED")}</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={24}>
                            <Form.Item name={["documents", "sendProforma"]} valuePropName={"checked"}>
                                <Checkbox
                                    onChange={(e) => {
                                        const emails = form.getFieldValue(["sendProformaEmails"]);
                                        const fieldsValue = form.getFieldsValue();
                                        if (
                                            (e.target.checked && !emails) ||
                                            (e.target.checked && !emails.length)
                                        ) {
                                            form.setFieldsValue({
                                                ...fieldsValue,
                                                account: {
                                                    ...fieldsValue,
                                                    sendProformaEmails: [""],
                                                },
                                            });
                                        }
                                    }}
                                    disabled={loading}
                                >
                                    {t("CLIENT.SEND_PROFORMAS")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.List name={["documents", "sendProformaEmails"]}>
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item required={false} key={field.key}>
                                                <Row justify={"space-between"} gutter={[5, 0]}>
                                                    <Col span={22}>
                                                        <Form.Item {...field} noStyle>
                                                            <Input disabled={loading} placeholder="Email" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Tooltip placement="topRight" title={t("DELETE")}>
                                                            <Button
                                                                disabled={loading}
                                                                type="default"
                                                                onClick={() => remove(field.name)}
                                                                icon={
                                                                    <FontAwesomeIcon icon={["fas", "times"]} />
                                                                }
                                                                danger
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                className="btn-flex-center"
                                                            />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                disabled={loading}
                                                onClick={() => add()}
                                                block
                                                icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                            >
                                                {t("CLIENT.ADD_EMAIL")}
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>

                        <Col span={24}>
                            <Typography.Title level={5}>{t("CLIENT.INVOICE_EMAILS")}</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={["documents", "sendInvoice"]} valuePropName={"checked"}>
                                <Checkbox
                                    onChange={(e) => {
                                        const emails = form.getFieldValue(["sendInvoiceEmails"]);
                                        const fieldsValue = form.getFieldsValue();
                                        if (
                                            (e.target.checked && !emails) ||
                                            (e.target.checked && !emails.length)
                                        ) {
                                            form.setFieldsValue({
                                                ...fieldsValue,
                                                account: {
                                                    ...fieldsValue,
                                                    sendInvoiceEmails: [""],
                                                },
                                            });
                                        }
                                    }}
                                    disabled={loading}
                                >
                                    {t("CLIENT.SEND_INVOICES")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(["documents", "sendInvoice"]) ? (
                                        <Form.Item
                                            label={t("CLIENT.SEND_INVOICES_FORMAT")}
                                            name={["documents", "sendInvoiceFormat"]}
                                        >
                                            <Select
                                                placeholder={t("CLIENT.SEND_INVOICES_FORMAT")}
                                                disabled={loading}
                                            >
                                                {commonStore.exportFormats.map((format) => (
                                                    <Select.Option key={format} value={format}>
                                                        {format.toUpperCase()}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    ) : null
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.List name={["documents", "sendInvoiceEmails"]}>
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                required={false}
                                                key={field.key}
                                                label={index === 0 ? "Emails" : ""}
                                            >
                                                <Row justify={"space-between"} gutter={[5, 0]}>
                                                    <Col span={22}>
                                                        <Form.Item {...field} noStyle>
                                                            <Input disabled={loading} placeholder="Email" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Tooltip placement="topRight" title={t("DELETE")}>
                                                            <Button
                                                                disabled={loading}
                                                                type="default"
                                                                onClick={() => remove(field.name)}
                                                                icon={
                                                                    <FontAwesomeIcon icon={["fas", "times"]} />
                                                                }
                                                                danger
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                className="btn-flex-center"
                                                            />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                disabled={loading}
                                                onClick={() => add()}
                                                block
                                                icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                            >
                                                {t("CLIENT.ADD_EMAIL")}
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5}>{t("CLIENT.PACKING_LIST_EMAILS")}</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={["documents", "sendPackingList"]} valuePropName={"checked"}>
                                <Checkbox
                                    onChange={(e) => {
                                        const emails = form.getFieldValue(["sendPackingListEmails"]);
                                        const fieldsValue = form.getFieldsValue();
                                        if (
                                            (e.target.checked && !emails) ||
                                            (e.target.checked && !emails.length)
                                        ) {
                                            form.setFieldsValue({
                                                ...fieldsValue,
                                                account: {
                                                    ...fieldsValue,
                                                    sendPackingListEmails: [""],
                                                },
                                            });
                                        }
                                    }}
                                    disabled={loading}
                                >
                                    {t("CLIENT.SEND_PACKING_LISTS")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.List name={["documents", "sendPackingListEmails"]}>
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item required={false} key={field.key}>
                                                <Row justify={"space-between"} gutter={[5, 0]}>
                                                    <Col span={22}>
                                                        <Form.Item
                                                            {...field}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                            //     },
                                                            // ]}
                                                            noStyle
                                                        >
                                                            <Input disabled={loading} placeholder="Email" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Tooltip placement="topRight" title={t("DELETE")}>
                                                            <Button
                                                                disabled={loading}
                                                                type="default"
                                                                onClick={() => remove(field.name)}
                                                                icon={
                                                                    <FontAwesomeIcon icon={["fas", "times"]} />
                                                                }
                                                                danger
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                className="btn-flex-center"
                                                            />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                disabled={loading}
                                                onClick={() => add()}
                                                block
                                                icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                            >
                                                {t("CLIENT.ADD_EMAIL")}
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Space direction={"horizontal"} size={[10, 10]}>
                        <Button type={"default"} onClick={() => setStep(2)}>Back</Button>
                        <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Space>
})