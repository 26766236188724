import React, {useEffect, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { useParams } from "react-router-dom";
import {Button, Collapse, Space} from "antd";
import {branchesService} from "../../../../services/branches/branches.service";
import {City, CityArea, Country} from "../../../../models/countries/country";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {notifications} from "../../../../services/notifications/notifications";
import {AddCityModal} from "./modal/add-city/add-city.modal";
import {AddCityAreaModal} from "./modal/add-city-area/add-city-area.modal";

export const BranchDataCountries: React.FC = () => {
    const {branchId} = useParams<{ branchId: string }>();
    const t = useTranslate();

    const [countries, setCountries] = useState<Country[]>([]);

    const [countryForNewCity, setCountryForNewCity] = useState<Country | null>(null);
    const [cityForNewArea, setCityForNewArea] = useState<City | null>(null);

    const reload = () => {
        branchesService
            .getCountries(branchId!)
            .then(countries => setCountries(countries));
    }

    useEffect(() => {
        reload();
    }, [branchId]);

    const removeCity = async (city: City) => {
        await branchesService.removeCity(city.id);
        notifications.successfully();
        reload();
    }

    const removeCityArea = async (area: CityArea) => {
        await branchesService.removeCityArea(area.id);
        notifications.successfully();
        reload();
    }

    return <>
        <Collapse
            items={countries.map((country) => ({
                key: country.id,
                label: country.name,
                collapsible: country.cities!.length > 0 ? "header" : "disabled",
                showArrow: country.cities!.length > 0,
                children: <>
                    <h4>Cities:</h4>
                    <Collapse
                        collapsible={"header"}
                        items={country.cities?.map(city => ({
                                key: 'city-' + city.id,
                                label: city.name,
                                collapsible: city.areas!.length > 0 ? "header" : "disabled",
                                showArrow: city.areas!.length > 0,
                                extra: <Space size={[5, 5]}>
                                    <Button type={"text"} size={"small"} onClick={() => setCityForNewArea(city)}>
                                        <FontAwesomeIcon icon={["fas", "plus"]} />
                                    </Button>
                                    <Button type={"text"} size={"small"} onClick={() => removeCity(city)}>
                                        <FontAwesomeIcon icon={["fas", "trash"]} />
                                    </Button>
                                </Space>,
                                children: <>
                                    <h4>City areas:</h4>
                                    <Collapse
                                        collapsible={"disabled"}
                                        items={city.areas?.map(area => ({
                                            key: 'area-' + area.id,
                                            label: area.name,
                                            collapsible: "disabled",
                                            showArrow: false,
                                            classNames: "collapse-no-line-height",
                                            extra: <Space size={[5, 5]}>
                                                <Button type={"text"} size={"small"}
                                                        onClick={() => removeCityArea(area)}>
                                                    <FontAwesomeIcon icon={["fas", "trash"]}/>
                                                </Button>
                                            </Space>
                                        })) as any}/>
                                </>,
                            }
                        )) as any} />
                </>,
                extra: <Space size={[5, 5]}>
                    <Button type={"text"} size={"small"} onClick={() => setCountryForNewCity(country)}>
                        <FontAwesomeIcon icon={["fas", "plus"]} />
                    </Button>
                </Space>
            })) as any}
        />
        {countryForNewCity ? (
            <AddCityModal
                country={countryForNewCity}
                onSubmit={reload}
                onClose={() => setCountryForNewCity(null)}
            />
        ) : null}
        {cityForNewArea ? (
            <AddCityAreaModal
                city={cityForNewArea}
                onSubmit={reload}
                onClose={() => setCityForNewArea(null)}
            />
        ) : null}
    </>;
}