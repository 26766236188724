import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {Country} from "../../../../../../models/countries/country";
import {branchesService} from "../../../../../../services/branches/branches.service";

export interface WidgetProps
{
    branchId: string;
    value?: any;
    onChange?: (value: any) => void;
}
export const UiInputWidgetCountry: React.FC<WidgetProps> = ({branchId, value, onChange}) => {

    const [disabled, setDisabled] = useState<boolean>(false);
    const [values, setValues] = useState<Country[]>([]);

    useEffect(() => {
        setDisabled(true);
        try {
            branchesService.getCountries(branchId).then(values => setValues(values));
        } finally {
            setDisabled(false);
        }
    }, [branchId])

    return (
        <Select
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            disabled={disabled}
        >
            {values.map(value => (
                <Select.Option value={value.id}>{value.name}</Select.Option>
            ))}
        </Select>
    )
}