import {AxiosInstance} from "axios";
import API, {BlobAndFilename, getBlobAndFileName} from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {
    Property, PropertyDocument,
    PropertyInventoryTask, PropertyInventoryTaskPart,
    PropertyInventoryTaskProperty, PropertyInventoryTaskPropertyReview, PropertyLocation,
    PropertyType
} from "../../models/property/property";
import {SystemUser} from "../../models/users/user/system.user";

export class PropertyService {
    private api: AxiosInstance = API;

    private responsibleUsers: Promise<SystemUser[]> | null = null;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<Property>> {
        return this.api.get<PaginatedResult<Property>>(
            'property',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    get(id: number): Promise<Property> {
        return this.api.get<Property>('property/' + id).then(x => x.data);
    }

    findByCode(code: string): Promise<Property[]> {
        return this.api.get<Property[]>('property/search', {params: {code}}).then(x => x.data);
    }

    getByCode(code: string): Promise<Property> {
        return this.api.get<Property>('property/code/' + code).then(x => x.data);
    }

    downloadAllAsXLSx(): Promise<BlobAndFilename> {
        return this.api.get('property/export', {responseType: 'blob'}).then(getBlobAndFileName);
    }

    removeProperty(id: number): Promise<any> {
        return this.api
            .delete<void>("property/" + id);
    }

    editProperty(id: number, data: any): Promise<Property> {
        return this.api
            .post<Property>("property/" + id, data)
            .then(x => x.data);
    }

    increasePropertyQty(id: number, data: any): Promise<Property> {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            // @ts-ignore
            let value: any = data[key];
            formData.append(key, value);
        })

        return this.api
            .post<Property>("property/" + id + "/increase-qty", formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(x => x.data);
    }

    decreasePropertyQty(id: number, data: any): Promise<Property> {
        return this.api
            .post<Property>("property/" + id + "/decrease-qty", data)
            .then(x => x.data);
    }

    createProperty(data: any): Promise<Property> {
        return this.api
            .post<Property>("property", data)
            .then(x => x.data);
    }

    getPropertyReviews(id: number, request: PaginatedRequest): Promise<PaginatedResult<PropertyInventoryTaskPropertyReview>> {
        return this.api.get<PaginatedResult<PropertyInventoryTaskPropertyReview>>(
            'property/' + id + '/reviews',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    getDocumentsForProperty(id: number, request: PaginatedRequest): Promise<PaginatedResult<PropertyDocument>> {
        return this.api.get<PaginatedResult<PropertyDocument>>(
            'property/' + id + '/documents',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    getDocuments(request: PaginatedRequest): Promise<PaginatedResult<PropertyDocument>> {
        return this.api.get<PaginatedResult<PropertyDocument>>(
            'property/documents',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    getTypes(request: PaginatedRequest): Promise<PaginatedResult<PropertyType>> {
        return this.api.get<PaginatedResult<PropertyType>>(
            'property/types',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    removeType(id: number): Promise<any> {
        return this.api
            .delete<void>("property/types/" + id);
    }

    editType(id: number, data: any): Promise<PropertyType> {
        return this.api
            .post<PropertyType>("property/types/" + id, data)
            .then(x => x.data);
    }

    createType(data: any): Promise<PropertyType> {
        return this.api
            .post<PropertyType>("property/types", data)
            .then(x => x.data);
    }

    getAllTypes(): Promise<PropertyType[]> {
        return this.api.get<PropertyType[]>('property/types/all').then(x => x.data);
    }

    getLocations(request: PaginatedRequest): Promise<PaginatedResult<PropertyLocation>> {
        return this.api.get<PaginatedResult<PropertyLocation>>(
            'property/locations',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    removeLocation(id: number): Promise<any> {
        return this.api
            .delete<void>("property/locations/" + id);
    }

    editLocation(id: number, data: any): Promise<PropertyLocation> {
        return this.api
            .post<PropertyLocation>("property/locations/" + id, data)
            .then(x => x.data);
    }

    createLocation(data: any): Promise<PropertyLocation> {
        return this.api
            .post<PropertyLocation>("property/locations", data)
            .then(x => x.data);
    }

    getAllLocations(): Promise<PropertyLocation[]> {
        return this.api.get<PropertyLocation[]>('property/locations/all').then(x => x.data);
    }

    getPropertyQRCode(id: number): Promise<Blob> {
        return this.api
            .get("property/" + id + "/qr-code", {responseType: "blob"})
            .then(v => v.data)
    }

    getPropertySticker(id: number): Promise<BlobAndFilename> {
        return this.api.get('property/' + id + '/label', {responseType: 'blob'}).then(getBlobAndFileName);
    }

    getPropertyCompactSticker(id: number): Promise<BlobAndFilename> {
        return this.api.get('property/' + id + '/label-compact', {responseType: 'blob'}).then(getBlobAndFileName);
    }

    getAllResponsibleUsers(): Promise<SystemUser[]> {
        if (!this.responsibleUsers) {
            this.responsibleUsers = this.api
                .get<SystemUser[]>('property/responsible-persons/all').then(x => x.data);
        }

        return this.responsibleUsers;
    }

    getAllInventoryTasks(request: PaginatedRequest): Promise<PaginatedResult<PropertyInventoryTask>> {
        return this.api.get<PaginatedResult<PropertyInventoryTask>>(
            'property/inventory-task',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    getInventoryTask(id: number): Promise<PropertyInventoryTask> {
        return this.api.get<PropertyInventoryTask>('property/inventory-task/' + id).then(x => x.data);
    }

    createInventoryTask(items: any[]): Promise<PropertyInventoryTask> {
        return this.api.post<PropertyInventoryTask>('property/inventory-task', {items}).then(x => x.data);
    }

    closeInventoryTask(id: number): Promise<void> {
        return this.api.post('property/inventory-task/' + id + '/close');
    }

    getInventoryPart(id: number): Promise<PropertyInventoryTaskPart> {
        return this.api.get<PropertyInventoryTaskPart>('property/inventory-task/part/' + id).then(x => x.data);
    }

    editInventoryPart(id: number, values: any): Promise<PropertyInventoryTaskPart> {
        return this.api.post<PropertyInventoryTaskPart>('property/inventory-task/part/' + id, values).then(x => x.data);
    }

    editInventoryPartWriteOffData(id: number, values: any): Promise<void> {
        return this.api.post<void>('property/inventory-task/part/' + id + '/write-off', values).then(x => x.data);
    }

    editInventoryPropertyWriteOffQty(id: number, values: any): Promise<PropertyInventoryTaskPart> {
        return this.api.post<PropertyInventoryTaskPart>('property/inventory-task/property/' + id + '/write-off', values).then(x => x.data);
    }

    downloadInventoryPartPDF(id: number): Promise<BlobAndFilename> {
        return this.api.get('property/inventory-task/part/' + id + '/pdf', {responseType: 'blob'}).then(getBlobAndFileName);
    }

    downloadInventoryPartWriteOffListPDF(id: number): Promise<BlobAndFilename> {
        return this.api.get('property/inventory-task/part/' + id + '/write-off-pdf', {responseType: 'blob'}).then(getBlobAndFileName);
    }

    downloadInventoryPartIgnoredListPDF(id: number): Promise<BlobAndFilename> {
        return this.api.get('property/inventory-task/part/' + id + '/ignored-pdf', {responseType: 'blob'}).then(getBlobAndFileName);
    }

    getInventoryPartProperties(id: number, request: PaginatedRequest): Promise<PaginatedResult<PropertyInventoryTaskPart>> {
        return this.api
            .get<PaginatedResult<PropertyInventoryTaskPart>>('property/inventory-task/part/' + id + '/property', {
                params: request.toParams(),
            })
            .then(x => x.data);
    }

    getInventoryWriteOffPartProperties(id: number, request: PaginatedRequest): Promise<PaginatedResult<PropertyInventoryTaskPart>> {
        return this.api
            .get<PaginatedResult<PropertyInventoryTaskPart>>('property/inventory-task/part/' + id + '/property-write-off', {
                params: request.toParams(),
            })
            .then(x => x.data);
    }

    getTaskProperty(taskId: number, id: number): Promise<PropertyInventoryTaskProperty> {
        return this.api.get<PropertyInventoryTaskProperty>('/property/inventory-task/' + taskId + '/property/' + id).then(x => x.data);
    }

    addTaskPropertyReview(propertyId: number, review: object): Promise<PropertyInventoryTaskPropertyReview> {
        const formData = new FormData();
        Object.keys(review).forEach(key => {
            // @ts-ignore
            let value: any = review[key];
            formData.append(key, value);
        })

        return this.api
            .post<PropertyInventoryTaskPropertyReview>('/property/inventory-task/property/' + propertyId + '/review', formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(x => x.data);
    }

    removeTaskPropertyReview(id: number): Promise<any> {
        return this.api.delete('/property/inventory-task/property/review/' + id).then(x => x.data);
    }

    removeFile(id: number): Promise<any> {
        return this.api
            .delete<void>("property/attachment/" + id);
    }

    downloadQtyChangePDF(id: number): Promise<BlobAndFilename> {
        return this.api.get('property/documents/' + id, {responseType: 'blob'}).then(getBlobAndFileName);
    }
}

export const propertyService = new PropertyService();
