import {Translate} from "../../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {DeclarationPart, UploadedDeclaration} from "../../../declaration.upload.model";
import {Button, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const columnsFactory = (
    declaration: UploadedDeclaration,
    onEdit: (part: DeclarationPart) => void,
    t: Translate
): ColumnsType<DeclarationPart> => {
    let columns: ColumnsType<DeclarationPart> = [
        {
            title: t("CODE"),
            dataIndex: "vedCode",
        },
        {
            title: t("DECLARATIONS.CODES_LIST.ADD_INFO"),
            dataIndex: "vedCodeShort",
            render: (_, value) =>
                [value.vedCodeShort, value.tarik, value.lisa, value.rahvuslik]
                    .filter(v => !!v)
                    .join(' / ')
        },
        {
            title: t("DECLARATIONS.CODES_LIST.OWNER_CODE"),
            dataIndex: "",
            render: (_, value) => value.provider.code
        },
        {
            title: t("CURRENCY"),
            dataIndex: "",
            render: (_, value) => value.price.currencyCode
        },
        {
            title: t("DECLARATION.VALUE"),
            dataIndex: "",
            render: (_, value) => value.price.value
        },
        {
            title: t("BOXES_QTY"),
            dataIndex: "boxesQty",
        },
        {
            title: t("DECLARATION.QTY"),
            dataIndex: "pcsQty",
        },
        {
            title: t("WEIGHT"),
            render: (_, value) => value.netWeight + " / " + value.grossWeight
        },
        {
            title: t("DECLARATION.TRANSPORT.CURRENCY_CODE"),
            render: (_, value) => value.transportPrice.currencyCode
        },
        {
            title: t("DECLARATION.TRANSPORT.AMOUNT"),
            render: (_, value) => value.transportPrice.value
        }
    ]

    if (declaration.status === 'verified') {
        columns = [
            ...columns,
            {
                title: "Tax A00",
                render: (_, value) => value.taxA00
            },
            {
                title: "Tax A30",
                render: (_, value) => value.taxA30
            },
            {
                title: "Tax B00",
                render: (_, value) => value.taxB00
            }
        ]
    }

    columns = [...columns, {
        title: t("ACTIONS"),
        render: (_, value) => <Space>
                <Button type={"text"} onClick={() => onEdit(value)}>
                    <FontAwesomeIcon icon={["fas", "edit"]} />
                </Button>
            </Space>
    }];

    return columns
}