import {TFunction} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import React from "react";
import {Link} from "react-router-dom";
import {BackorderItem} from "../../../../../models/backorder/backorder.item";
import Date from "../../../../date/date";

export const columnsFactory = (t: TFunction<"translation">): ColumnsType<BackorderItem> => {
    return [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "sku",
            key: "sku",
            render: (sku: string) => sku !== "DELIVERY" ? <Link to={"/catalog/article/" + sku} className="link article-link">{sku}</Link> : sku
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description"
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "qty"
        },
        {
            title: t("ORDERS.TITLE"),
            dataIndex: "orders",
            key: "orders",
            render: (orders: number[]) => orders
                .map<React.ReactNode>(order => <Link to={"/orders/" + order}>{order}</Link>)
                .reduce((prev, curr) => [prev, ', ', curr])
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item: string) => <>{item ? (<Date dateString={item} showTime={false} />) : '-'}</>
        },
        {
            title: t("UPDATED_AT"),
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (item: string) => <>{item ? (<Date dateString={item} showTime={false} />) : '-'}</>
        },
    ];
}