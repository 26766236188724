import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ShippingRule} from "../../../../../models/shipping/shipping";
import {branchesService} from "../../../../../services/branches/branches.service";
import {Button, Space, Table, Typography, Upload} from "antd";
import {columnsFactory, groupedColumnsFactory} from "./branch.data.rules.columns";
import {useRequiredStore} from "../../../../../utils/store";
import {BranchDataStoreContext} from "../../branch.data.store";
import {BranchDataRulesEditModal} from "./branch.data.rules.edit.modal";
import {Country} from "../../../../../models/countries/country";
import {BranchDataRulesImportModal} from "./branch.data.rules.import.modal";

export interface ShippingRulesGroup {
    rules: ShippingRule[];
    countries: Country[];
    key: string;
}

export const BranchDataRules: React.FC = () => {
    const {courierId} = useParams<{ courierId: string }>();
    const t = useTranslate();

    const store = useRequiredStore(BranchDataStoreContext);

    const [editableRule, setEditableRule] = useState<ShippingRule | null>(null);
    const [ruleModalShown, setRuleModalShown] = useState<boolean>(false);
    const [importRulesModalShown, setImportRulesModalShown] = useState<boolean>(false);

    const [shippingRules, setShippingRules] = useState<ShippingRulesGroup[]>();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        reloadRules();
    }, []);

    const onCompleteRuleEdit = async (sourceRule: ShippingRule | null, rule: ShippingRule) => {
        let newRule: ShippingRule;

        if (sourceRule) {
            newRule = await branchesService.editShippingRule(sourceRule.id, rule);
            reloadRules();
        } else {
            newRule = await branchesService.addShippingRule(courierId!, rule);
            reloadRules();
        }

        setRuleModalShown(false);
        setEditableRule(null);

        return newRule;
    }

    const onRemoveRule = async(rule: ShippingRule) => {
        await branchesService.removeShippingRule(rule.id);
        reloadRules();
    }

    const onRuleEdit = (rule: ShippingRule) => {
        setRuleModalShown(true);
        setEditableRule(rule);
    }

    const onCancelRuleEdit = () => {
        setRuleModalShown(false);
        setEditableRule(null);
    }

    const reloadRules = () => {
        setLoading(true);
        setShippingRules([]);
        branchesService.getAllShippingRules(courierId!).then(rules => {
            const groups: {[K: string]: ShippingRulesGroup} = {};
            rules.map(rule => {
                const countries = rule.countries
                    .map(c => c.country.name)
                    .filter((id, index, self) => self.indexOf(id) === index)
                    ;
                const key = countries.join('|') || 'ALL';
                const group = groups[key] || {rules: [], countries: countries, key: key};
                group.rules.push(rule);
                groups[key] = group;
            })
            setShippingRules(Object.values(groups))
            setLoading(false);
        })
    }

    const columns = useMemo(() => columnsFactory(t, store.branch?.currencyCode!, onRuleEdit, onRemoveRule), []);
    const groupColumns = useMemo(() => groupedColumnsFactory(t), []);

    if (loading) {
        return <>Loading...</>;
    }

    return <Space direction="vertical" size={[10, 10]}>
        <Typography.Title level={3}>Shipping rules</Typography.Title>

        {ruleModalShown ? (
            <BranchDataRulesEditModal
                onSubmit={onCompleteRuleEdit}
                onClose={onCancelRuleEdit}
                rule={editableRule}
            />
        ) : null}
        {importRulesModalShown ? (
            <BranchDataRulesImportModal
                courierId={courierId!}
                onComplete={() => reloadRules()}
                onClose={() => setImportRulesModalShown(false)}
            />
        ) : null}

        <Space direction="horizontal" size={[10, 10]}>
            <Button className="ant-btn ant-btn-primary" onClick={() => setRuleModalShown(true)}>
                {"Create a new rule"}
            </Button>
            <Button className="ant-btn ant-btn-primary" onClick={() => setImportRulesModalShown(true)}>
                {"Import from CSV (replace all)"}
            </Button>
        </Space>

        <Table
            columns={groupColumns}
            dataSource={shippingRules}
            defaultExpandedRowKeys={["key"]}
            pagination={false}
            expandedRowRender={group =>
                <Table
                    columns={columns}
                    dataSource={group.rules}
                    pagination={false}
                />
            }
        />

    </Space>
}