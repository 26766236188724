import {FieldGroupConfig} from "../../../models/filter/filter.group";
import {FieldTypeName} from "../../../models/filter/filter.field.type.name";
import {Translate} from "../../../hooks/translate.hook";
import {ApplicationStatus} from "../../../models/production/application.model";

export const filterGroupsFactory = (t: Translate): FieldGroupConfig[] => {
    return [
        {
            visibleName: 'STATUS',
            name: 'statuses',
            fields: [
                {
                    name: 'statuses',
                    type: FieldTypeName.SELECT,
                    choices: Object.values(ApplicationStatus).map(value => {
                        return {
                            name: t('PRODUCTION_REQUEST.STATUS.' + value.toUpperCase()),
                            value: value
                        }
                    }),
                    multiple: true
                }
            ],
        }
    ];
}