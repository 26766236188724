import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues
} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";
import {UiInputWidgetCity} from "../../ui/input/widget/city/ui.input.widget.city";
import {UiInputWidgetCityArea} from "../../ui/input/widget/city-area/ui.input.widget.city-area";

interface PayerReceiverModalProps {
    data: PayerOrReceiverDetails,
    visible: boolean,
    type: "payer" | "receiver",
    onClose: () => void,
    onSubmit: (type: "payer" | "receiver", values: PayerReceiverFormValues) => Promise<any>
    withAddressType?: boolean;
}
export const PayerReceiverModal: React.FC<PayerReceiverModalProps> = observer((
    {
        data,
        visible,
        type,
        onClose,
        onSubmit,
        withAddressType = false
    }
) => {
    const t = useTranslate();
    const {langStore} = useRequiredStore(RootStoreContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const defaultValues: PayerReceiverFormValues = {...data, country: data.country.id as string};

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={type === "payer" ? t("PAYER_SELLER_DETAILS.EDIT_PAYER") : t("PAYER_SELLER_DETAILS.EDIT_RECEIVER")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={type + "_edit_form"}
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSubmit(type, values)
                            .then(() => {
                                form.resetFields();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                // onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    {withAddressType ? (
                        <Col span={24}>
                            <Form.Item
                                name="addressType"
                                label={t("ADDRESSES.TYPE.TITLE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    disabled={loading}
                                    placeholder={t("ADDRESSES.TYPE")}
                                >
                                    {["short", "full"].map((type) => (
                                        <Select.Option key={type} value={type}>
                                            {t("ADDRESSES.TYPE."  + type.toUpperCase())}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col span={24}>
                        <Form.Item
                            name="companyName"
                            label={t("COMPANY_NAME")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("COMPANY_NAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label={t("FIRSTNAME")}
                        >
                            <Input disabled={loading} type="text" placeholder={t("EMAIL")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            label={t("LASTNAME")}
                        >
                            <Input disabled={loading} type="text" placeholder={t("EMAIL")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="vat"
                            label={"VAT"}
                        >
                            <Input disabled={loading} type="text" placeholder="VAT"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="customsCode"
                            label={"EORI"}
                        >
                            <Input disabled={loading} type="text" placeholder="EORI"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="regCode"
                            label={t("REG_NUMBER")}
                        >
                            <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="country"
                            label={t("COUNTRY")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Select
                                disabled={loading}
                                showSearch
                                optionFilterProp={"children"}
                                placeholder={t("COUNTRY_SELECT")}
                            >
                                {langStore.countries.map(({id, name}) => (
                                    <Select.Option
                                        key={id as string}
                                        value={id as string}
                                    >
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="region"
                            label={t("REGION")}
                        >
                            <Input disabled={loading} type="text" placeholder={t("REGION")}/>
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.addressType !== curValues.addressType
                        }
                    >
                        {() => (
                            <>
                                {form.getFieldValue('addressType') === 'full' ? (
                                    <>
                                        <Col span={4}>
                                            <Form.Item
                                                name="cityType"
                                                label={t("ADDRESSES.CITY_TYPE")}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("ADDRESSES.CITY_TYPE")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues.country !== curValues.country}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name="city"
                                                        label={t("CITY")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                            },
                                                        ]}
                                                    >
                                                        <UiInputWidgetCity
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue('country')}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {type === 'receiver' ? (
                                            <Col span={12}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city}
                                                >
                                                    {() => (
                                                        <Form.Item
                                                            name="cityArea"
                                                            label={t("CITY_AREA")}
                                                        >
                                                            <UiInputWidgetCityArea
                                                                disabled={loading}
                                                                countryCode={form.getFieldValue('country')}
                                                                cityName={form.getFieldValue('city')}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        ) : null}
                                        <Col span={12}>
                                            <Form.Item
                                                name="index"
                                                label={t("INDEX")}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues.country !== curValues.country}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name="city"
                                                        label={t("CITY")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                            },
                                                        ]}
                                                    >
                                                        <UiInputWidgetCity
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue('country')}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {type === 'receiver' ? (
                                            <Col span={12}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city}
                                                >
                                                    {() => (
                                                        <Form.Item
                                                            name="cityArea"
                                                            label={t("CITY_AREA")}
                                                        >
                                                            <UiInputWidgetCityArea
                                                                disabled={loading}
                                                                countryCode={form.getFieldValue('country')}
                                                                cityName={form.getFieldValue('city')}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        ) : null}
                                        <Col span={12}>
                                            <Form.Item
                                                name="index"
                                                label={t("INDEX")}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </>
                        )}
                    </Form.Item>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                                prevValues.addressType !== curValues.addressType
                            }
                        >
                            {() => (
                                <Form.Item
                                    name="address"
                                    label={t("ADDRESS")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                        },
                                    ]}
                                >
                                    <Input disabled={loading || form.getFieldValue('addressType') === 'full'} type="text" placeholder={t("ADDRESS")} />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                        prevValues.addressType !== curValues.addressType
                    }
                >
                    {() => form.getFieldValue('addressType') === 'full' ? (
                        <Row gutter={10}>
                            <Col span={4}>
                                <Form.Item
                                    name="complexType"
                                    label={t("ADDRESSES.COMPLEX.TYPE")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.COMPLEX.TYPE")} />
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <Form.Item
                                    name="complexName"
                                    label={t("ADDRESSES.COMPLEX.NAME")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.COMPLEX.NAME")} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="streetType"
                                    label={t("ADDRESSES.STREET.TYPE")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.TYPE")} />
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item
                                    name="streetName"
                                    label={t("ADDRESSES.STREET.NAME")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.NAME")} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="streetNo"
                                    label={t("ADDRESSES.STREET.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.NO")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="blockNo"
                                    label={t("ADDRESSES.BLOCK.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.BLOCK.NO")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="entranceNo"
                                    label={t("ADDRESSES.ENTRANCE.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.ENTRANCE.NO")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="floorNo"
                                    label={t("ADDRESSES.FLOOR.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.FLOOR.NO")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="apartmentNo"
                                    label={t("ADDRESSES.APARTMENT.NO")}
                                >
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.APARTMENT.NO")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                </Form.Item>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label={t("PHONE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("PHONE")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label={t("EMAIL")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("EMAIL")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
})
