import React from "react";
import { action, computed, makeObservable, observable } from "mobx";
import {
    Warehouse,
    WarehouseWithSort,
} from "../../../../models/warehouses/warehouse";
import { OrdersCartEditFormValues } from "../../../../models/orders/orders-cart/orders.cart.edit.form.values";
import { OrdersCart } from "../../../../models/orders/orders-cart/orders-create/orders.cart";
import {
    OrdersCreateTableLastRecord,
    OrdersCreateTableRecord,
} from "../../../../models/orders/orders-cart/orders-create/orders.create.table.record";
import {
    generateCreateOrderRequestQuery,
    generateData,
} from "../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/orders.cart.create.helper";
import { articlesService } from "../../../../services/articles/articles.service";
import { OrdersCreateAddValues } from "../../../../models/orders/orders-cart/orders-create/orders.create.add.values";
import { ordersCreateService } from "../../../../services/orders/orders-cart/orders.create.service";
import { OrdersCartRequest } from "../../../../models/orders/orders-cart/orders-create/orders.cart.request";
import { CustomerAccount } from "../../../../models/accounts/customer-account/customer.account";
import { CustomerAccountAddress } from "../../../../models/accounts/customer-account/address/customer.account.address";
import {
    getDataFromUrl,
    updateUrlQueryString,
} from "../../../../utils/helpers";
import { OrdersCreateMissingItem } from "../../../../models/orders/orders-cart/orders-create/orders.create.response";
import queryString from "query-string";
import {
    OrderCreateType
} from "../../../../models/orders/orders-cart/orders-create/orders-cart-create-helper/order.create.type";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {Translate} from "../../../../hooks/translate.hook";

export class OrdersCreateStore {
    @observable
    public notInitialized: boolean = false;

    @observable
    public hasAllFields: boolean = true;

    @observable
    public warehouses: Warehouse[] = [];

    @observable
    public urlQueryString?: string;

    @observable
    public editFormValues: OrdersCartEditFormValues;

    @observable
    public cart: OrdersCart | null = null;

    @observable
    public loading: boolean = false;

    @observable
    public tableData: OrdersCreateTableRecord[] &
        OrdersCreateTableLastRecord[] = [];

    @observable
    public checkoutButtonDisabled: boolean = false;

    @observable
    public missingItems: OrdersCreateMissingItem[] = [];

    @observable
    public _missingItemsAlertVisible: boolean = false;

    constructor(
        public type: OrderCreateType,
        public account: CustomerAccount,
        private number: string,
        private updateNotCompletedOrdersAmount: (value: number) => void,
        private navigate: NavigateFunction,
        private translate: Translate
    ) {
        makeObservable(this);

        this.editFormValues = {
            type: type,
            discountPercent: this.account.discount,
            useBackorder: this.account.useBackorder,
            warehouses: type === 'reservation' ? [] : (
                OrdersCreateStore.areWarehousesInvalid(
                    this.account.warehouses
                )
                    ? []
                    : [this.account.warehouses[0].warehouse.id]
            ),
            ignoreRegionalProhibitions: false,
        }

        if (type === 'regular') {
            this.setWarehouses(this.account.warehouses);
        }

        this.searchArticle = this.searchArticle.bind(this);
        this.onLngChangeCreate = this.onLngChangeCreate.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.goToCheckout = this.goToCheckout.bind(this);
        this.getCart();
    }

    @action
    private setMissingItemsAlertVisible(value: boolean): void {
        this._missingItemsAlertVisible = value;
    }

    @action
    public setUrlString(value: string): void {
        this.urlQueryString = value;
    }

    @action
    private setHasAllFields(value: boolean): void {
        this.hasAllFields = value;
    }

    @action
    private setMissingItems(value: OrdersCreateMissingItem[]): void {
        this.missingItems = value;
    }

    @action
    private setWarehouses(warehouses: WarehouseWithSort[]) {
        this.warehouses = warehouses
            .slice()
            .sort((a, b) => a.sort! - b.sort!)
            .map((item) => item.warehouse);
    }

    @action
    private setTableData(
        data: OrdersCreateTableRecord[] & OrdersCreateTableLastRecord[]
    ): void {
        this.tableData = data;
    }

    @action
    private setLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    private setCart(cartPreview: OrdersCart) {
        this.cart = cartPreview;
        this.setTableData(
            generateData(cartPreview, this.warehouses, this.editFormValues, this.translate)
        );
    }

    @action
    private setNotInitialized(value: boolean): void {
        this.notInitialized = value;
    }

    @action
    private updateEditFormValues(
        discount: number,
        warehouses: string[],
        ignoreRegionalProhibitions: boolean,
        useBackorder: boolean
    ): void {
        this.editFormValues.discountPercent = discount;
        this.editFormValues.warehouses = warehouses;
        this.editFormValues.ignoreRegionalProhibitions =
            ignoreRegionalProhibitions;
        this.editFormValues.useBackorder =
            useBackorder
    }

    @action
    private setCheckoutButtonDisabled(value: boolean): void {
        this.checkoutButtonDisabled = value;
    }
    public async searchArticle(search: string) {
        const articles = await articlesService.searchArticle(search);
        return articles.map(({ id, name, description, multiple }) => ({
            value: id,
            label: name + " - " + description,
            multiple: multiple
        }));
    }

    private static areWarehousesInvalid(
        warehouses: WarehouseWithSort[]
    ): boolean {
        return !warehouses || !warehouses.length;
    }

    private static isDiscountInvalid(discount: any): boolean {
        return typeof discount !== "number";
    }

    private static areAddressesInvalid(
        addresses: CustomerAccountAddress[]
    ): boolean {
        return !addresses || !addresses.length;
    }

    public async getCart() {
        this.setNotInitialized(true);

        this.setLoading(true);
        const formValuesFromUrl = getDataFromUrl<OrdersCartEditFormValues>();

        this.updateEditFormValues(
            formValuesFromUrl.discountPercent
                ? formValuesFromUrl.discountPercent
                : this.account.discount,
            formValuesFromUrl.warehouses
                ? OrdersCreateStore.generateWarehouses(
                      formValuesFromUrl.warehouses
                  )
                : this.account.warehouses[0]
                ? [this.account.warehouses[0].warehouse.id]
                : [],
            formValuesFromUrl.ignoreRegionalProhibitions === "true",
            formValuesFromUrl.useBackorder === "true",
        );
        this.setLoading(false);

        if (
            OrdersCreateStore.areWarehousesInvalid(this.account.warehouses) ||
            OrdersCreateStore.isDiscountInvalid(this.account?.discount) ||
            OrdersCreateStore.areAddressesInvalid(
                this.account?.shippingAddresses
            )
        ) {
            this.setNotInitialized(false);
            this.setHasAllFields(false);
            return;
        }

        if (
            this.editFormValues.discountPercent === undefined &&
            !this.editFormValues.warehouses.length
        ) {
            this.updateEditFormValues(
                this.account.discount,
                [this.warehouses[0].id],
                false,
                false
            );
        }

        this.updateUrlEditFormValues();

        this.setCart(
            await ordersCreateService.getCart(
                this.account.id!,
                this.editFormValues
            )
        );

        this.setLoading(false);
        this.setNotInitialized(false);
    }

    public addToCart = async (values: OrdersCreateAddValues) => {
        await ordersCreateService.addToCart(this.account.id!, {...values, type: this.type});
        await this.reloadCart();
    };

    public async updateCartItem(
        articleId: number | string,
        requestValues: OrdersCartRequest
    ) {
        await ordersCreateService.updateCart(
            this.account.id!,
            articleId,
            requestValues
        );
        await this.reloadCart();
    }

    public async updateCart(value: {
        discountPercent: number;
        warehouses: string[];
        ignoreRegionalProhibitions: boolean;
        useBackorder: boolean;
    }) {
        this.setLoading(true);
        this.updateEditFormValues(
            value.discountPercent,
            value.warehouses,
            value.ignoreRegionalProhibitions,
            value.useBackorder
        );
        this.updateUrlEditFormValues();
        this.setCart(
            await ordersCreateService.getCart(
                this.account.id!,
                this.editFormValues
            )
        );
        this.setLoading(false);
    }

    public async deleteCartItem(articleId: number | string) {
        await ordersCreateService.removeCartItem(this.account.id!, this.type, articleId);
        await this.reloadCart();
    }

    public async clearCart() {
        await ordersCreateService.removeCart(this.account.id!);
        await this.reloadCart();
    }

    public async reloadCart() {
        this.setLoading(true);
        this.setCart(
            await ordersCreateService.getCart(
                this.account.id!,
                this.editFormValues
            )
        );
        this.setLoading(false);
    }

    public onLngChangeCreate(): void {
        this.reloadCart();
    }

    private updateUrlEditFormValues() {
        const link = this.getLink();
        this.setUrlString(link);
        updateUrlQueryString(link);
    }

    public async moveCartItemsToMissingItems(): Promise<void> {
        this.setLoading(true);
        await ordersCreateService.moveToMissingItems(
            this.account.id,
            this.cart!.items.filter((item) => item.qty === 0).map(
                (item) => item.article.id
            )
        );
        this.setCart(
            await ordersCreateService.getCart(
                this.account.id!,
                this.editFormValues
            )
        );
        this.setLoading(false);
    }

    @computed
    public get isMoveButtonDisabled(): boolean {
        if (this.cart) {
            return !this.cart.items.some((item) => item.qty === 0);
        }
        return true;
    }

    private getLink(): string {
        return queryString.stringify({
            warehouses: this.editFormValues.warehouses,
            discountPercent: this.editFormValues.discountPercent,
            ignoreRegionalProhibitions: this.editFormValues.ignoreRegionalProhibitions,
            useBackorder: this.editFormValues.useBackorder,
        });
    }

    private static generateWarehouses(warehouses: string | string[]) {
        return Array.isArray(warehouses) ? warehouses : [warehouses];
    }

    public async importCart(file: File, onProgress: (percent: number) => void) {
        return await ordersCreateService.importFile(
            this.type,
            this.account.id,
            file,
            onProgress,
        );
    }

    public async createOrder() {
        this.setCheckoutButtonDisabled(true);
        const requestData = generateCreateOrderRequestQuery({
            cart: this.cart!,
            editFormValues: this.editFormValues,
            type: this.type
        });

        try {
            const result = await ordersCreateService.createOrder(
                this.account.id!,
                requestData
            );
            this.updateNotCompletedOrdersAmount(result.orders.length);
            if (result.missingItems.length > 0) {
                this.setMissingItems(result.missingItems);
            } else {
                this.goToCheckout();
            }
        } catch (e: any) {
            this.setCheckoutButtonDisabled(false);
        }
    }

    public goToCheckout(): void {
        this.navigate("/clients/" + this.number + "/cart/checkout");
    }

    @computed
    public get missingItemsAlertVisible(): boolean {
        return (
            this.cart?.items.some((item) => item.qty !== item.requestedQty) ||
            false
        );
    }
}

export const OrdersCreateStoreContext =
    React.createContext<OrdersCreateStore | null>(null);
