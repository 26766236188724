import React, {useMemo, useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Space, Typography} from "antd";
import {Claim, ClaimItem, ClaimItemReject, ClaimStatus} from "../../../../models/claims/claims";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TableViewSwitcher} from "../../../common/table-view-switcher/table-view-switcher";
import {ClaimPartsKanbanView} from "./kanban/claim.parts.kanban";
import {ClaimPartsListView} from "./list/claim.parts.list";
import {claimService} from "../../../../services/claims/claims.service";
import {ClaimRejectModal} from "../../common/claim.reject.modal";
import {notifications} from "../../../../services/notifications/notifications";
import {CreditInvoiceCreateModal} from "../../credit/credit-invoice-create-modal/credit-invoice-create-modal";
import {SetWarehouseModal} from "../../actions/set-warehouse/set-warehouse-modal";
import {ClaimGuessModal} from "../../guess/claim-guess-modal/claim.guess.modal";
import {ApplyDiscountModal} from "../../actions/apply-discount/apply-discount-modal";

enum RejectModalState {
    closed,
    requestClarificationAll,
    rejectAll
}

export interface ClaimPartsProps {
    claim: Claim;
    loading: boolean;
    onUpdate: () => void;
}

export const ClaimParts: React.FC<ClaimPartsProps> = ({claim, loading, onUpdate}) => {
    const t = useTranslate();

    const [rejectModalState, setRejectModalState] = useState<RejectModalState>(RejectModalState.closed);

    const [createCreditModalShown, setCreateCreditModalShown] = useState<boolean>(false);
    const [createCreditModalAll, setCreateCreditModalAll] = useState<boolean>(false);

    const [warehouseModalShown, setWarehouseModalShown] = useState<boolean>(false);
    const [guessModalShown, setGuessModalShown] = useState<boolean>(false);
    const [applyDiscountModalShown, setApplyDiscountModalShown] = useState<boolean>(false);

    const [selectedItems, setSelectedItems] = useState<ClaimItem[]>([]);

    const limitExceeded = claim.items.length > 10;

    const acceptAll = async () => {
        await claimService.acceptAllItems(claim.id);
        onUpdate();
        notifications.successfully();
    }

    const accept = async () => {
        await claimService.acceptItems(claim.id, {items: selectedItems.map(i => i.id)});
        onUpdate();
        notifications.successfully();
    }

    const onComplete = async (value: ClaimItemReject) => {
        if (rejectModalState === RejectModalState.rejectAll) {
            if (selectedItems.length > 0) {
                await claimService.rejectItems(claim.id, {...value, items: selectedItems.map(i => i.id)});
            } else {
                await claimService.rejectAllItems(claim.id, value);
            }
        } else if (rejectModalState === RejectModalState.requestClarificationAll) {
            await claimService.needClarificationAllItems(claim.id, value);
        }
        onUpdate();
        notifications.successfully();
    }

    const merge = async () => {
        await claimService.merge(claim.id, {items: selectedItems.map(i => i.id)});
        onUpdate();
        notifications.successfully();
    }

    const ClaimPartsListViewNode = useMemo(() =>
        <ClaimPartsListView claim={claim} onUpdate={onUpdate} setSelectedItems={setSelectedItems} loading={loading} />,[claim, onUpdate, setSelectedItems]);
    const ClaimCanBanViewNode = useMemo(() =>
        <ClaimPartsKanbanView claim={claim} onUpdate={onUpdate} />, [claim, loading, onUpdate]);

    return (
        <>
            <Typography.Title level={3}>{t("CLAIMS.CLAIM.PARTS")}</Typography.Title>

            <TableViewSwitcher
                views={[
                    {
                        node: ClaimPartsListViewNode,
                        icon: 'list',
                        extra: <Space size={[10, 10]}>
                            <Button
                                disabled={selectedItems.length === 0}
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "check-circle"]} color={"green"} />}
                                onClick={accept}
                            >
                                {t('ACCEPT')}
                            </Button>
                            <Button
                                disabled={selectedItems.length === 0}
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} color={"red"} />}
                                onClick={() => setRejectModalState(RejectModalState.rejectAll)}
                            >
                                {t('REJECT')}
                            </Button>
                            <Button
                                disabled={selectedItems.length === 0}
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "file-invoice"]} color={"black"} />}
                                onClick={() => {
                                    setCreateCreditModalAll(false);
                                    setCreateCreditModalShown(true);
                                }}
                            >
                                {t('INVOICING.CREATE_CREDIT')}
                            </Button>
                            <Button
                                disabled={selectedItems.length === 0}
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "question-circle"]} />}
                                onClick={() => merge()}
                            >
                                {t('MERGE')}
                            </Button>
                            <Button
                                disabled={selectedItems.length === 0}
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "question-circle"]} />}
                                onClick={() => setWarehouseModalShown(true)}
                            >
                                {t('WAREHOUSES.SET_WAREHOUSE')}
                            </Button>
                            <Button
                                disabled={selectedItems.length === 0}
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "percent"]} />}
                                onClick={() => setApplyDiscountModalShown(true)}
                            >
                                {t('CLAIM.APPLY_DISCOUNT')}
                            </Button>
                        </Space>
                    }, {
                        node: ClaimCanBanViewNode,
                        icon: 'border-all',
                        extra: <Space size={[10, 10]}>
                            <Button
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "check-circle"]} color={"green"} />}
                                onClick={acceptAll}
                            >
                                {t('ACCEPT_ALL')}
                            </Button>
                            <Button
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "exclamation-triangle"]} color={"red"} />}
                                onClick={() => setRejectModalState(RejectModalState.rejectAll)}
                            >
                                {t('REJECT_ALL')}
                            </Button>
                            <Button
                                size={"middle"}
                                icon={<FontAwesomeIcon icon={["fas", "file-invoice"]} color={"black"} />}
                                onClick={() => {
                                    setCreateCreditModalAll(true);
                                    setCreateCreditModalShown(true);
                                }}
                            >
                                {t('INVOICING.CREATE_CREDIT_ALL')}
                            </Button>
                        </Space>
                    }
                ]}
                extra={
                    <Space size={[10, 10]}>
                        <Button
                            size={"middle"}
                            icon={<FontAwesomeIcon icon={["fas", "question-circle"]} />}
                            onClick={() => setRejectModalState(RejectModalState.requestClarificationAll)}
                        >
                            {t('REQUEST_MORE_INFO_FOR_ALL')}
                        </Button>
                        {claim.status === ClaimStatus.ACCEPTED ? (
                            <a href={"/api/v3/claim/" + claim.id + "/1c-export"}>
                                <Button
                                    size={"middle"}
                                    icon={<FontAwesomeIcon icon={["fas", "file-code"]} color={"#777"} />}
                                >
                                    {t('GENERATE_CSV_FOR_1C')}
                                </Button>
                            </a>
                        ): null}
                        <Button
                            size={"middle"}
                            icon={<FontAwesomeIcon icon={["fas", "question-circle"]} />}
                            onClick={() => setGuessModalShown(true)}
                        >
                            {t('GUESS_INVOICES_FOR_UNKNOWNS')}
                        </Button>
                    </Space>
                }
                defaultViewIndex={0}
                disabled={limitExceeded}
            />

            <ClaimRejectModal
                title={t('SELECT_REASON')}
                visible={rejectModalState !== RejectModalState.closed}
                onComplete={onComplete}
                onClose={() => setRejectModalState(RejectModalState.closed)}
            />

            {createCreditModalShown ? (
                <CreditInvoiceCreateModal
                    claim={claim}
                    items={createCreditModalAll ? [] : selectedItems}
                    onClose={() => setCreateCreditModalShown(false)}
                    onCompleted={() => onUpdate()}
                />
            ) : null}

            {warehouseModalShown ? (
                <SetWarehouseModal
                    claim={claim}
                    items={selectedItems}
                    onClose={() => setWarehouseModalShown(false)}
                    onCompleted={() => onUpdate()}
                />
            ) : null}

            {applyDiscountModalShown ? (
                <ApplyDiscountModal
                    claim={claim}
                    items={selectedItems}
                    onClose={() => setApplyDiscountModalShown(false)}
                    onCompleted={() => onUpdate()}
                />
            ) : null}

            {guessModalShown ? (
                <ClaimGuessModal
                    claimId={claim.id}
                    onClose={() => setGuessModalShown(false)}
                    onComplete={() => onUpdate()}
                />
            ) : null}
        </>
    );
}