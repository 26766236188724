import { AxiosInstance } from "axios";
import API from "../../utils/api";
import {Attentions} from "../../models/attentions/attention";

export class AttentionService {
    api: AxiosInstance = API;

    getAttentions(): Promise<Attentions> {
        return this.api
            .get<Attentions>("attentions")
            .then((x) => x.data);
    }
}

export const attentionService = new AttentionService();
