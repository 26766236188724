import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useParams} from "react-router-dom";
import {declarationService} from "../../../services/declarations/declarations.services";
import {UploadedDeclaration} from "../upload/declaration.upload.model";
import {LoadingSpin} from "../../common/loading-spin/loading.spin";
import {Button, Col, Dropdown, Menu, Row, Space, Typography} from "antd";
import {DeclarationCart} from "../upload/common/declaration-cart/declaration.cart";
import {DeclarationIncludedCodesModal} from "../common/included-codes/declaration.included-codes.modal";
import {downloadBlobFile} from "../../../utils/blob.download";
import {DownloadXMLModal} from "./modal/download-xml/download-xml.modal";
import {DeclarationsStatus} from "../status/declaration.status";
import {UploadModal} from "./modal/upload/upload.modal";
import {useTranslate} from "../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";
export const DeclarationView: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const { id } = useParams<{ id: string }>();
    const t = useTranslate();
    const navigate = useNavigate();

    const [declaration, setDeclaration] = useState<UploadedDeclaration>();

    const [includedCodesShown, setIncludedCodesShown] = useState<boolean>(false);

    const [downloadXMLModalShown, setDownloadXMLModalShown] = useState<boolean>(false);

    const [uploadModalShown, setUploadModalShown] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const reload = async () => {
        setLoading(true);
        try {
            await declarationService.getByNumber(id!).then(declaration => setDeclaration(declaration));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        reload();
    }, [id])

    if (!declaration) {
        return <LoadingSpin />;
    }

    const downloadSourceCSV = async () => {
        const {blob, filename} = await declarationService.downloadSourceCSV(declaration.number);
        downloadBlobFile(blob, filename)
    }

    const downloadAsXML = async (maxDocuments: number) => {
        const {blob, filename} = await declarationService.downloadAsXML(declaration.number, maxDocuments);
        downloadBlobFile(blob, filename)
    }

    const downloadAsJSON = () => {
        downloadBlobFile(
            new Blob([JSON.stringify(declaration, null, 2)], {
                type: "application/json",
            }),
            declaration.number + ".json"
        )
    }

    const downloadCompareTable = async () => {
        const {blob, filename} = await declarationService.downloadCompareTable(declaration.number);
        downloadBlobFile(blob, filename)
    }

    const cancel = async () => {
        setLoading(true);
        try {
            await declarationService.cancel(declaration.number)
        } finally {
            setLoading(false)
            reload();
        }
    }

    return (
        <>
            <PageHeader
                style={{ padding: "0 0 40px 0" }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("DECLARATION.TITLE")} {declaration.number}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/declarations");
                }}
                extra={<DeclarationsStatus status={declaration.status} large />}
            >
                <Row justify={"space-between"}>
                    <Col>
                        <Space direction="horizontal" size={[10, 10]}>
                            <Button type={"primary"} onClick={() => setIncludedCodesShown(true)}>Included rows list</Button>
                            <Button type={"primary"} onClick={() => downloadCompareTable()}>Compare table</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="horizontal" size={[10, 10]}>
                            <Button
                                type={"primary"}
                                style={{backgroundColor: "#31ad00", borderColor: "#31ad00"}}
                                onClick={() => setUploadModalShown(true)}
                                disabled={loading}
                            >
                                {t("UPLOAD.TITLE")}
                            </Button>
                            <Dropdown
                                disabled={loading}
                                placement={"bottomLeft"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item onClick={() => downloadSourceCSV()}>
                                            Download source CSV
                                        </Menu.Item>
                                        <Menu.Item onClick={() => setDownloadXMLModalShown(true)}>
                                            Download as XML
                                        </Menu.Item>
                                        <Menu.Item onClick={() => downloadAsJSON()}>
                                            Download as JSON
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button
                                    type="primary"
                                    disabled={loading}
                                >
                                    {t("DOWNLOAD.DO_DOWNLOAD")}
                                </Button>
                            </Dropdown>
                            <Button
                                type={"primary"}
                                danger
                                onClick={() => cancel()}
                                disabled={loading}
                            >
                                {t("CANCEL")}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </PageHeader>

            {!loading ? (
                <DeclarationCart declaration={declaration} reload={reload} />
            ) : null}

            {includedCodesShown ? (
                <DeclarationIncludedCodesModal
                    declaration={declaration}
                    onClose={() => setIncludedCodesShown(false)}
                />
            ) : null}

            {downloadXMLModalShown ? (
                <DownloadXMLModal
                    onClose={() => setDownloadXMLModalShown(false)}
                    onComplete={(maxDocuments) => downloadAsXML(maxDocuments)}
                />
            ) : null}
            {uploadModalShown ? (
                <UploadModal
                    number={declaration.number}
                    onClose={() => setUploadModalShown(false)}
                    onComplete={() => reload()}
                />
            ) : null}
        </>
    );
}