import React from "react";
import {Checkbox, Col, Form, FormInstance, Input, Row, Select, Typography} from "antd";
import {UiInputWidgetCity} from "../../../../../common/ui/input/widget/city/ui.input.widget.city";
import {UiInputWidgetCityArea} from "../../../../../common/ui/input/widget/city-area/ui.input.widget.city-area";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {UIInputWidgetCountry} from "../../../../../common/ui/input/widget/country/ui.input.widget.country";

interface Props {
    title: string;
    name: string;
    loading: boolean;
    form: FormInstance;
    disabled?: boolean;
    setDisabled?: (value: boolean) => void;
}

export const AgentNewStepAddressesValues: React.FC<Props> = ({title, name, loading, form, disabled, setDisabled }) => {

    const t = useTranslate();

    return (
        <>
            <Typography.Title level={4}>{title}</Typography.Title>
            {setDisabled ? (
                <Row gutter={10}>
                    <Col span={24} style={{marginBottom: 15}}>
                        <Checkbox
                            checked={disabled}
                            onChange={v => setDisabled(v.target.checked)}
                        >
                            Shipping address is same as billing
                        </Checkbox>
                    </Col>
                </Row>
            ) : (
                <Row gutter={10}>
                    <Col span={24} style={{marginBottom: 15}}>
                        &nbsp;
                    </Col>
                </Row>
            )}
            {!disabled ? (
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name={[name, "addressType"]}
                            label={t("ADDRESSES.TYPE.TITLE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Select
                                disabled={loading}
                                placeholder={t("ADDRESSES.TYPE")}
                            >
                                {["short", "full"].map((type) => (
                                    <Select.Option key={type} value={type}>
                                        {t("ADDRESSES.TYPE."  + type.toUpperCase())}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={[name, "name"]}
                            label={t("NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("NAME")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={[name, "companyName"]}
                            label={t("COMPANY_NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("COMPANY_NAME")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={[name, "firstName"]} label={t("FIRSTNAME")}>
                            <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={[name, "lastName"]} label={t("LASTNAME")}>
                            <Input disabled={loading} type="text" placeholder={t("LASTNAME")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={[name, "vat"]} label={"VAT"}>
                            <Input disabled={loading} type="text" placeholder={"EE00000000"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={[name, "customsCode"]} label={"EORI"}>
                            <Input disabled={loading} type="text" placeholder={"EORI"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={[name, "regCode"]} label={t("REG_NUMBER")}>
                            <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={[name, "country"]}
                            label={t("COUNTRY")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <UIInputWidgetCountry />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={[name, "region"]} label={t("REGION")}>
                            <Input disabled={loading} type="text" placeholder={t("REGION")} />
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.billingAddress?.addressType !== curValues.billingAddress?.addressType
                        }
                    >
                        {() => (
                            <>
                                {form.getFieldValue([name, 'addressType']) === 'full' ? (
                                    <>
                                        <Col span={4}>
                                            <Form.Item
                                                name={[name, "cityType"]}
                                                label={t("ADDRESSES.CITY_TYPE")}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("ADDRESSES.CITY_TYPE")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues[name]?.country !== curValues[name]?.country}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name={[name, "city"]}
                                                        label={t("CITY")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                            },
                                                        ]}
                                                    >
                                                        <UiInputWidgetCity
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue([name, 'country'])}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues[name]?.city !== curValues[name]?.city}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name={[name, "cityArea"]}
                                                        label={t("CITY_AREA")}
                                                    >
                                                        <UiInputWidgetCityArea
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue([name, 'country'])}
                                                            cityName={form.getFieldValue([name, 'city'])}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={[name, "index"]}
                                                label={t("INDEX")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                    },
                                                ]}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues[name]?.country !== curValues[name]?.country}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name={[name, "city"]}
                                                        label={t("CITY")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                            },
                                                        ]}
                                                    >
                                                        <UiInputWidgetCity
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue([name, 'country'])}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) => prevValues[name]?.city !== curValues[name]?.city}
                                            >
                                                {() => (
                                                    <Form.Item
                                                        name={[name, "cityArea"]}
                                                        label={t("CITY_AREA")}
                                                    >
                                                        <UiInputWidgetCityArea
                                                            disabled={loading}
                                                            countryCode={form.getFieldValue([name, 'country'])}
                                                            cityName={form.getFieldValue([name, 'city'])}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={[name, "index"]}
                                                label={t("INDEX")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                    },
                                                ]}
                                            >
                                                <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </>
                        )}
                    </Form.Item>
                    <Col span={24}>
                        <Form.Item
                            name={[name, "address"]}
                            label={t("ADDRESS")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("ADDRESS")} />
                        </Form.Item>
                    </Col>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues[name]?.addressType !== curValues[name]?.addressType
                        }
                    >
                        {() => form.getFieldValue([name, 'addressType']) === 'full' ? (
                            <Row gutter={10}>
                                <Col span={4}>
                                    <Form.Item
                                        name={[name, "complexType"]}
                                        label={t("ADDRESSES.COMPLEX.TYPE")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.COMPLEX.TYPE")} />
                                    </Form.Item>
                                </Col>
                                <Col span={20}>
                                    <Form.Item
                                        name={[name, "complexName"]}
                                        label={t("ADDRESSES.COMPLEX.NAME")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.COMPLEX.NAME")} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name={[name, "streetType"]}
                                        label={t("ADDRESSES.STREET.TYPE")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.TYPE")} />
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name={[name, "streetName"]}
                                        label={t("ADDRESSES.STREET.NAME")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.NAME")} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name={[name, "streetNo"]}
                                        label={t("ADDRESSES.STREET.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.NO")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name={[name, "blockNo"]}
                                        label={t("ADDRESSES.BLOCK.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.BLOCK.NO")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name={[name, "entranceNo"]}
                                        label={t("ADDRESSES.ENTRANCE.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.ENTRANCE.NO")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name={[name, "floorNo"]}
                                        label={t("ADDRESSES.FLOOR.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.FLOOR.NO")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name={[name, "apartmentNo"]}
                                        label={t("ADDRESSES.APARTMENT.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.APARTMENT.NO")} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : null}
                    </Form.Item>
                    <Col span={12}>
                        <Form.Item
                            name={[name, "phone"]}
                            label={t("PHONE")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("PHONE")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={[name, "email"]}
                            label={t("EMAIL")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("EMAIL")} />
                        </Form.Item>
                    </Col>
                </Row>
            ) : null}
        </>
    )
}