import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Row, Space, Typography} from "antd";
import {AgentNewContext} from "../../agent.new.modal";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {AgentNewStepAddressesValues} from "./agent.new.step.addresses.values";

export const AddressesStep: React.FC = () => {

    const {setStep, getValue, setValue, fields} = React.useContext(AgentNewContext);

    const t = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields(fields);
    }, [fields])

    const [shippingSameAsBilling, setShippingSameAsBilling] = useState<boolean>(false);

    const defaultValues: any = {
        billingAddress: getValue('billingAddress'),
        shippingAddress: getValue('shippingAddress'),
        addressesOptions: getValue('addressesOptions')
    }

    return <Space direction="vertical" size={[15, 15]} style={{marginTop: 40}}>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                await form.validateFields();
                setValue('billingAddress', values.billingAddress)
                setValue('shippingAddress', shippingSameAsBilling ? values.billingAddress : values.shippingAddress)
                setValue('addressesOptions', values.addressesOptions)
                setStep(6)
            }}
        >
            <Typography.Title level={3}>{"Addresses"}</Typography.Title>
            <Row gutter={20}>
                <Col span={12}>
                    <AgentNewStepAddressesValues
                        title={"Billing address"}
                        name={"billingAddress"}
                        loading={loading}
                        form={form}
                    />
                </Col>
                <Col span={12}>
                    <AgentNewStepAddressesValues
                        title={"Shipping address"}
                        name={"shippingAddress"}
                        loading={loading}
                        form={form}
                        disabled={shippingSameAsBilling}
                        setDisabled={setShippingSameAsBilling}
                    />
                </Col>
                <Col span={12}>
                    <Typography.Title level={4}>{"Options"}</Typography.Title>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item
                                name={["addressesOptions", "allowedAddressManagement"]}
                                label={t("ADDRESSES.ALLOW_MANAGEMENT")}
                                valuePropName={"checked"}
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Space direction={"horizontal"} size={[10, 10]}>
                        <Button type={"default"} onClick={() => setStep(4)}>Back</Button>
                        <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Space>
}