import React, {useMemo} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Claim, ClaimItem} from "../../../../../models/claims/claims";
import {Modal, Table} from "antd";
import {columnsFactory} from "./claim.parts.columns";
import i18n from "i18next";
import {TableRowSelection} from "antd/es/table/interface";

export interface ClaimPartsListViewProps {
    claim: Claim;
    loading: boolean;
    onUpdate: () => void;
    setSelectedItems: (items: ClaimItem[]) => void;
}

export const ClaimPartsListView: React.FC<ClaimPartsListViewProps> = ({claim, loading, onUpdate, setSelectedItems}) => {

    const t = useTranslate();

    const columns = useMemo(() => columnsFactory(t, claim, onUpdate), [i18n.language]);

    const rowSelection: TableRowSelection<ClaimItem> = useMemo(() => {
        return {
            type: "checkbox",
            //selectedRowKeys: selectedItems.map(i => i.id),
            onChange: (selectedRowKeys: React.Key[], selectedRows: ClaimItem[]) => {
                setSelectedItems(selectedRows)
            },
            getCheckboxProps: (item: ClaimItem) => ({
                name: "item_" + String(item.id)
            }),
        }
    }, []);

    return (
        <Table
            loading={loading}
            dataSource={[...claim.items]}
            columns={columns}
            pagination={false}
            rowSelection={rowSelection}
            rowKey={"id"}
            virtual
        />
    );
}