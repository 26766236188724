import React, {useMemo} from "react";
import {Col, Form, Row, Space} from "antd";
import {CartViewFilter} from "./model/model";
import {useForm} from "antd/es/form/Form";

interface CardsViewFiltersProps {
    filters: CartViewFilter[];
    onChange: (values: Record<string, string[]>) => void;
    initialValues: Record<string, string | string[]>;
}

export const CardsViewFilters: React.FC<CardsViewFiltersProps> = ({filters, onChange, initialValues}) => {

    const [form] = useForm();

    const groupedByLines = useMemo(() => {
        const ls: CartViewFilter[][] = [];
        filters.forEach(item => {
            if (!ls[item.line]) {
                ls[item.line] = [];
            }
            ls[item.line].push(item)
        })
        return ls;
    }, [filters])

    return (
        <Form
            form={form}
            onValuesChange={(_, av) => onChange(av)}
            initialValues={initialValues}
        >
            <Space direction={"vertical"} size={[0, 0]}>
                {groupedByLines.map((group, key) => (
                    <Row gutter={[10, 10]} key={key}>
                        {group.map((item, key) => {
                            const Widget = item.widget;

                            let size = Math.floor(24 / group.length)
                            if (group.length > 1 && key === group.length - 1) {
                                size = 24 - (size * (group.length - 1));
                            }

                            return (
                                <Col key={key} xs={24} sm={24} md={24} lg={size} xl={size} xxl={size}>
                                    <Form.Item name={item.name}>
                                        <Widget placeholder={item.placeholder} form={form} />
                                    </Form.Item>
                                </Col>
                            )
                        })}
                    </Row>
                ))}
            </Space>
        </Form>
    )
}