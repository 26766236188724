import React, {useState} from "react";
import {Col, Form, Input, Modal, Row} from "antd";
import {Country} from "../../../../../../models/countries/country";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {branchesService} from "../../../../../../services/branches/branches.service";

export interface Props {
    country: Country;
    onSubmit(): void;
    onClose(): void;
}

export const AddCityModal: React.FC<Props> = ({country, onSubmit, onClose}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            open={true}
            confirmLoading={loading}
            title={t("BRANCH.DATA.COUNTRIES.ADD_CITY.TITLE")}
            onOk={() => form.submit()}
            onCancel={() => onClose()}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                onFinish={async (values) => {
                    await form.validateFields();
                    setLoading(true);
                    try {
                        await branchesService.createCity(country.id!, values);
                        onSubmit();
                        onClose();
                    } catch (e: any) {
                        form.setFields(e.response?.data?.fields);
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input disabled={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
