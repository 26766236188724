import {Translate} from "../../../../../hooks/translate.hook";
import React from "react";
import {Link} from "react-router-dom";
import {ContainerItem} from "../../../../../models/containers/containers";
import {Article} from "../../../../../models/articles/article";
import {SmartColumn} from "../../../../common/smart-table/smart-table.model";
import {FieldType} from "../../../../common/smart-table/smart-table.field-config";

export const columnsFactory = (t: Translate): SmartColumn<ContainerItem>[] => {
    return [
        {
            visibleName: t("ARTICLES.NAME"),
            name: "article",
            render: (article: Article) => <Link to={"/articles/" + article.name}>{article.name}</Link>,
            type: FieldType.STRING,
            filterable: true
        },
        {
            visibleName: t("QTY"),
            name: "qty",
            type: FieldType.STRING
        },
        {
            visibleName: t("RESERVED_QTY"),
            name: "reservedQty",
            type: FieldType.STRING
        },
        {
            visibleName: t("BOXES_QTY"),
            name: "boxesQty",
            type: FieldType.STRING
        }
    ];
}