import React, {useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {propertyService} from "../../../../services/property/property.service";
import {SmartTableDataHandler} from "../../../common/smart-table/smart-table.data-handler";
import {SmartTable} from "../../../common/smart-table/smart-table";
import {columnFactory} from "./list.property.columns";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Button, Col, Dropdown, Menu, Row, Space, Typography} from "antd";
import {Property} from "../../../../models/property/property";
import {EditPropertyModal} from "../edit/edit.property.modal";
import {downloadBlobFile} from "../../../../utils/blob.download";
import {notifications} from "../../../../services/notifications/notifications";
import {Link} from "react-router-dom";
import {UiInputWidgetPropertySearch} from "../../ui/input/widget/property-search/ui.input.widget.property-search";
import {useNavigate} from "react-router";
import {} from "@fortawesome/react-fontawesome";

export const PropertyList: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();

    const handler = useMemo(() => new SmartTableDataHandler(r => propertyService.getAll(r)), []);
    const columns = useMemo(() => columnFactory(t), [t])

    const [editableProperty, setEditableProperty] = useState<Property | null>(null);
    const [editableModalShown, setEditableModalShown] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const openEditableModal = (property: Property | null) => {
        setEditableProperty(property);
        setEditableModalShown(true);
    }

    const editableModalClosed = async () => {
        setEditableModalShown(false);
        await handler.reloadItemsWithLastRequest();
        notifications.successfully();
    }

    const remove = async (property: Property) => {
        await propertyService.removeProperty(property.id);
        await handler.reloadItemsWithLastRequest();
        notifications.successfully();
    }

    const getSticker = (property: Property) => {
        propertyService.getPropertySticker(property.id).then(
            ({blob, filename}) => downloadBlobFile(blob, filename)
        );
    }

    const getCompactSticker = (property: Property) => {
        propertyService.getPropertyCompactSticker(property.id).then(
            ({blob, filename}) => downloadBlobFile(blob, filename)
        );
    }

    const downloadAsXLSX = async () => {
        setLoading(true);
        try {
            const {blob, filename} = await propertyService.downloadAllAsXLSx();
            downloadBlobFile(blob, filename);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Space size={[10, 10]} direction={"vertical"}>
                <Typography.Title level={3} style={{margin: "5 0px"}}>
                    {t("PROPERTY.PROPERTY_LIST.TITLE")}
                </Typography.Title>

                <Row justify={"space-between"} style={{marginBottom: 20}} gutter={[10, 10]}>
                    <Col>
                        <Space direction={"vertical"} size={[10, 10]}>
                            <Space direction={"horizontal"}>
                                <Link to={"/property/find-by-qr-code"}>
                                    <Button
                                        type={"default"}
                                        disabled={loading}
                                    >
                                        Scan QR-code
                                    </Button>
                                </Link>
                                <Button
                                    type={"primary"}
                                    onClick={() => openEditableModal(null)}
                                    disabled={loading}
                                >
                                    Create a new Property
                                </Button>
                            </Space>
                            <Space direction={"horizontal"}>
                                <UiInputWidgetPropertySearch onChange={v => navigate('/property/' + v)} />
                            </Space>
                        </Space>
                    </Col>
                    <Col>
                        <Dropdown
                            placement="bottomRight"
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        onClick={() => downloadAsXLSX()}
                                        disabled={loading}
                                    >
                                        Download XLSX
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type="primary">{t("ACTION")}</Button>
                        </Dropdown>
                    </Col>
                </Row>


                <SmartTable
                    columns={columns}
                    handler={handler}
                    actions={[
                        {
                            title: 'Edit',
                            icon: 'edit',
                            onClick: (v) => openEditableModal(v)
                        },
                        {
                            title: 'Remove',
                            icon: 'trash',
                            onClick: (v) => remove(v)
                        },
                        {
                            title: 'Download compact label',
                            icon: 'sticky-note',
                            onClick: (v) => getCompactSticker(v)
                        },
                        {
                            title: 'Download full label',
                            icon: 'sticky-note',
                            onClick: (v) => getSticker(v)
                        }
                    ]}
                />
            </Space>
            {editableModalShown ? (
                <EditPropertyModal onClose={editableModalClosed} property={editableProperty} />
            ) : null}
        </>
    )
}