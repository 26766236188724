import React, {useState} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { useRequiredStore } from "../../../../../utils/store";
import { OrderDataStoreContext } from "../../order.data.store";
import { observer } from "mobx-react";
import { displayPrice } from "../../../../../models/prices/price.helper";
import { TableFooter } from "../../../../common/table-footer/table.footer";
import {generateDetailsData} from "../../../../../models/orders/order-data/order-data-helper/order.data.helper";
import { OrderDetailsItem } from "../../../../../models/orders/order-data/order.data";
import { displayOrderDetailsPrice } from "../../../../../models/orders/order-data/helper/order.data.helper";
import "./order.data.details.scss";
import { PricesLegend } from "../../../../common/prices-legend/prices.legend";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { ExpandedTable } from "../../../../common/order-details/expanded.table";
import {Button, Tag, Tooltip} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import {convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {useNavigate} from "react-router";
import {OrderDataCreateReturnModal} from "../order-data-create-return-modal/order-data-create-return-modal";

export const OrderDataDetails: React.FC = observer(() => {
    const t = useTranslate();
    const store = useRequiredStore(OrderDataStoreContext);
    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const navigate = useNavigate();

    const [returnModalItem, setReturnModalItem] = useState<OrderDetailsItem | null>();

    const columns: ColumnsType<OrderDetailsItem> = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "order_data_order_detail_articleName",
            width: "135px",
            render: (article: string) =>
                article.toUpperCase() === "DELIVERY" ? (
                    t("DELIVERY.TITLE")
                ) : (
                    <Link to={"/articles/" + article} style={{ whiteSpace: "nowrap" }}>
                        {article}
                    </Link>
                ),
        },
        {
            title: t("ORDER.SHIPPING"),
            key: "shipping",
            width: "320px",
            render: (_: any, item: OrderDetailsItem) => <>
                {Object.keys(store.orderMeta?.iAv[item.id] || {}).map(key => {
                    const data = store.orderMeta?.iAv[item.id][key]!;
                    let color = "red";
                    let stock = 0;
                    if (data.available) {
                        color = "green";
                    } else if (data.stock > 0) {
                        color = "orange";
                        stock = data.stock;
                    }
                    return <Tag color={color}>{store.orderMeta?.iAv[item.id][key].name} {stock > 0 ? '(' + stock + ')' : ''}</Tag>
                })}
            </>
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "articleDescription",
            key: "order_data_order_detail_articleDescription",
            width: "320px",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "order_data_order_detail_price",
            width: "167px",
            render: (price, row) => {
                if (!price) {
                    return null;
                }
                return displayOrderDetailsPrice(
                    { type: row.priceType, price },
                    t("PRICES." + row.priceType.toUpperCase())
                );
            },
        },
        {
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "order_data_order_detail_quantity",
            width: "125px",
            render: (_: any, item) => item.quantity + " / " + item.qtyRequested
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "order_data_order_detail_totalPrice",
            width: "140px",
            render: (item) => (item ? <>{displayPrice(item)}</> : null),
        },
        {
            title: t("ACTIONS.TITLE"),
            key: "order_data_order_detail_actions",
            render: (_: any, item) => <>
                <Button
                    shape={"round"}
                    size={"middle"}
                    icon={<FontAwesomeIcon icon={["fas", "arrow-circle-left"]} />}
                    onClick={() => setReturnModalItem(item)}
                >
                </Button>
            </>
        }
    ];

    return (
        <>
            <ExpandedTable<OrderDetailsItem>
                title="DETAILS"
                pageKey="order_page_order_details"
                columns={columns}
                columnsConfig={convertTableColumnsToColumnConfigs(columns)}
                tableData={generateDetailsData(store.order!.items)}
                tableLoading={store.loading}
                tableFooter={<></>}
                additionButtons={
                    attributes.includes("order_edit") &&
                    (store.order!.status === "not_completed" || store.order!.status === "not_confirmed" || store.order!.status === "waiting_for_goods") ? (
                        <>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="btn data-description-btn"
                                    onClick={store.showEditItemsTable}
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </>
                    ) : null
                }
            >
                <PricesLegend style={{ marginBottom: 24 }} />
            </ExpandedTable>
            {returnModalItem ? (
                <OrderDataCreateReturnModal
                    item={returnModalItem}
                    onClose={() => setReturnModalItem(null)}
                    onCompleted={(returnDocument) => {
                        navigate("/returns/" + returnDocument.id);
                    }}
                />
            ) : null}
        </>
    );
});
