import React from "react";
import {Select} from "antd";
import {CustomerAccountAddress} from "../../../../../models/accounts/customer-account/address/customer.account.address";
import {useTranslate} from "../../../../../hooks/translate.hook";

export interface WidgetProps
{
    shippingAddresses: CustomerAccountAddress[];
    disabled: boolean;
    value?: any;
    onChange?: (value: any) => void;
}
export const UIInputWidgetAccountAddress: React.FC<WidgetProps> = ({shippingAddresses, disabled, value, onChange}) => {

    const t = useTranslate();

    return (
        <Select
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
            disabled={disabled}
            placeholder={t("ADDRESS_DEFAULT")}
        >
            {shippingAddresses.map(address => (
                <Select.Option value={address.id}>{address.name} ({address.country.name}, {address.city}, {address.address})</Select.Option>
            ))}
        </Select>
    )
}