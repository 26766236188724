import React, {useMemo, useState} from "react";
import {Button, Form, Select, Space} from "antd";
import {Courier, Shipment} from "../../../../../models/parcels/shipment";
import {shipmentsService} from "../../../../../services/shipments/shipments.service";
import {ProductFields} from "../../../shipments-wholesale/ship-modal/additional-fields/product-fields";
import {LoadUnloadFields} from "../../../shipments-wholesale/ship-modal/additional-fields/load-unload-fields";
import {config} from "../../../shipments-wholesale/ship-modal/config";
import {IncotermsFields} from "../../../shipments-wholesale/ship-modal/additional-fields/incoterms-fields";
import {PickupDateFields} from "../../../shipments-wholesale/ship-modal/additional-fields/pickup-date-fields";
import {
    CourierServicePayerFields
} from "../../../shipments-wholesale/ship-modal/additional-fields/courier-service-payer-fields";
import {
    AutoSelectNearestOffice
} from "../../../shipments-wholesale/ship-modal/additional-fields/auto-select-nearest-office";

interface SelectCourierStepAutoProps {
    couriers: Courier[];
    shipment: Shipment;
    onComplete: () => void;
}

export const SelectCourierAuto: React.FC<SelectCourierStepAutoProps> = ({couriers, shipment, onComplete}) => {

    const [form] = Form.useForm();

    const courierList = useMemo(() => couriers.filter(c => c.automated), [couriers]);

    const [loading, setLoading] = useState<boolean>(false);

    return <Space direction="vertical" size={[15, 15]}>
        <Form
            form={form}
            layout="vertical"
            initialValues={{courier: courierList[0].id}}
            onFinish={values => {
                form.validateFields().then(async () => {
                    setLoading(true);
                    try {
                        const data: Record<string, any> = {courier: values.courier};
                        const usedConfig = config[values.courier];
                        if (usedConfig) {
                            if (usedConfig.useLoadUnload) {
                                data.loadData = {
                                    // load
                                    loadType       : values.loadType,
                                    loadFromHour   : parseInt(values.loadTimeFrom?.format('H') || 0),
                                    loadFromMinute : parseInt(values.loadTimeFrom?.format('m') || 0),
                                    loadToHour     : parseInt(values.loadTimeTo?.format('H') || 0),
                                    loadToMinute   : parseInt(values.loadTimeTo?.format('m') || 0),
                                    loadNotes      : values.loadNotes || '',
                                    // unload
                                    unloadType       : values.unloadType,
                                    unloadFromHour   : parseInt(values.unloadTimeFrom?.format('H') || 0),
                                    unloadFromMinute : parseInt(values.unloadTimeFrom?.format('m') || 0),
                                    unloadToHour     : parseInt(values.unloadTimeTo?.format('H') || 0),
                                    unloadToMinute   : parseInt(values.unloadTimeTo?.format('m') || 0),
                                    unloadNotes      : values.unloadNotes || '',
                                }
                                if (values.loadDate) {
                                    data.loadData.loadDate = values.loadDate.format('YYYY-MM-DD');
                                }
                                if (values.unloadDate) {
                                    data.loadData.unloadDate = values.unloadDate.format('YYYY-MM-DD');
                                }
                            }
                            if (usedConfig.usePickupDate) {
                                data.loadData = {
                                    loadDate: values.loadDate.format('YYYY-MM-DD')
                                }
                            }
                            if (usedConfig.useProduct) {
                                data.product = values.product;
                            }
                            if (usedConfig.useIncoterms) {
                                data.incoterms = values.incoterms;
                            }
                            if (usedConfig.courierServicePayer) {
                                data.courierServicePayer = values.courierServicePayer;
                            }
                            if (usedConfig.useAutoSelectNearestOffice) {
                                data.autoSelectNearestOffice = values.autoSelectNearestOffice;
                            }
                        }

                        await shipmentsService.reShip(shipment.id, values.courier, data);
                        onComplete();
                    } finally {
                        setLoading(false);
                    }
                })
            }}
        >
            <Space direction="vertical" size={[15, 15]}>
                <Form.Item
                    name="courier"
                    label="Courier"
                >
                    <Select disabled={loading}>
                        {courierList.map(courier =>
                            <Select.Option value={courier.id}>{courier.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle={true}
                    shouldUpdate={(o, n) => o.courier !== n.courier}
                >
                    {() => (
                        <>
                            <ProductFields loading={loading} courierName={form.getFieldValue('courier')} />
                            <IncotermsFields loading={loading} courierName={form.getFieldValue('courier')} />
                            <LoadUnloadFields loading={loading} courierName={form.getFieldValue('courier')} />
                            <PickupDateFields loading={loading} courierName={form.getFieldValue('courier')} />
                            <CourierServicePayerFields loading={loading} courierName={form.getFieldValue('courier')} />
                            <AutoSelectNearestOffice loading={loading} courierName={form.getFieldValue('courier')} />
                        </>
                    )}
                </Form.Item>
            </Space>
            <Space direction="horizontal" size={[15, 15]}>
                <Button type={"primary"} onClick={() => form.submit()} loading={loading}>Go</Button>
            </Space>
        </Form>
    </Space>
}