import React, {useMemo} from "react";
import {observer} from "mobx-react";
import {useTranslate} from "../../../hooks/translate.hook";
import {useRequiredStore} from "../../../utils/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MissingItemsAddAllAvailableModal} from "./missing-items-add-all-available.modal";
import { Export } from "../../common/export/export";
import {Alert, Button, Checkbox, Col, Dropdown, Form, Menu, Popconfirm, Row, Select, Space, Tooltip} from "antd";
import FilteredTable from "../../common/filtered-table/filtered.table";
import {MissingItem, MissingItemsArticle, MissingItemsLinkedOrder} from "../../../models/missing-items/missing.item";
import {MissingItemsPartStore, MissingItemsStoreContext} from "./missing.items.part.store";
import {AgentDataStoreContext} from "../../agents/agent-data/agent.data.store";
import "./missing.items.part.scss";
import {Link} from "react-router-dom";
import {FieldGroupConfig} from "../../../models/filter/filter.group";
import {FieldTypeName} from "../../../models/filter/filter.field.type.name";
import {convertTableColumnsToColumnConfigs} from "../../../utils/table";
import {ComponentPropsFromRoute} from "../../../routers/routers";

export const MissingItemsPart: React.FC<ComponentPropsFromRoute> = observer(({requiredConfigs}) => {

    const t = useTranslate();
    const agentDataStore = useRequiredStore(AgentDataStoreContext);

    const active = requiredConfigs!.active;

    const store = useMemo(
        () => new MissingItemsPartStore(agentDataStore.account!.id!, !active),
        [agentDataStore.account, active]
    );

    const isFiltered = (): boolean => {
        const filters = store.dataHandler.lastRequest?.filters;
        if (filters) {
            return Object.keys(filters).length > 1;
        }

        return false;
    }

    const columns: any = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "article",
            key: "article",
            render: (item: MissingItemsArticle) => <a href={"/articles/" + item.name} target="_blank">{item.name}</a>
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "article",
            key: "article",
            render: (item: MissingItemsArticle) => <>{item.description}</>
        },
        {
            title: t("ORDERS.LINKED"),
            dataIndex: "linkedOrders",
            key: "missing_items_report_linked_orders",
            render: (items: MissingItemsLinkedOrder[]) =>
                items.map((order, index) => (
                    <Link to={"/orders/" + order.id}>
                        {order.id}
                        {index !== items.length - 1 ? ", " : null}
                    </Link>
                )),
        },
        {
            title: "References",
            dataIndex: "linkedOrders",
            key: "missing_items_report_references",
            render: (item: MissingItemsLinkedOrder[]) => item.map(i => i.ref).join(", "),
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "qty"
        },
        {
            title: t("APPLIED_QUANTITY"),
            dataIndex: "appliedQty",
            key: "appliedQty"
        }
    ];

    agentDataStore.account!.warehouses.forEach(({warehouse}) => {
        if (warehouse.type !== "consignation") {
            columns.push({
                title: warehouse.name,
                dataIndex: warehouse.id,
                key: warehouse.id,
                render: (_: any, row: MissingItem) => {
                    const foundWarehouse = row.article.stocks.find(item => item.warehouse.id === warehouse.id);
                    return foundWarehouse ? foundWarehouse.amount : "";
                }
            });
        }
    });

    columns.push(
        {
            title: "In transit",
            dataIndex: "article",
            key: "inTransitQty",
            render: (a: any) => a.inTransitQty
        }
    );

    columns.push(
        {
            title: "",
            dataIndex: "remove",
            key: "remove",
            width: "50px",
            render: (_: any, item: MissingItem) => (
                <>
                    {active ? (
                        <Tooltip placement="topRight" title={t("HIDE")}>
                            <Button
                                className="btn-remove btn"
                                type="default"
                                size={"small"}
                                icon={<FontAwesomeIcon icon={["fas", "eye-slash"]}/>}
                                onClick={() => store.hideItem(item)}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="topRight" title={t("SHOW")}>
                            <Button
                                className="btn-remove btn"
                                type="default"
                                size={"small"}
                                icon={<FontAwesomeIcon icon={["fas", "eye"]}/>}
                                onClick={() => store.showItem(item)}
                            />
                        </Tooltip>
                    )}
                </>
            )
        });

    const rowSelection: any = {
        type: "checkbox",
        onChange: (selectedRowKeys: number[], selectedRows: MissingItem[]) => {
            store.checkArticles(selectedRowKeys);
        },
        getCheckboxProps: (record: MissingItem) => ({
            //disabled: record.article.stocks.every(item => item.amount === 0), // Column configuration not to be checked
            // name: record.article.name,
        }),
    };

    const filters: FieldGroupConfig[] = [
        {
            name: "sku",
            visibleName: t("CODE"),
            fields: [
                {
                    name: "sku",
                    type: FieldTypeName.STRING
                }
            ]
        },
        {
            name: "date",
            visibleName: t("DATE"),
            fields: [
                {
                    name: "date",
                    type: FieldTypeName.DATE_RANGE
                }
            ]
        },
        {
            name: "linkedOrders",
            visibleName: t("ORDERS.LINKED"),
            fields: [
                {
                    name: "linkedOrders",
                    type: FieldTypeName.INTEGER
                }
            ]
        },
        {
            name: "references",
            visibleName: "References",
            fields: [
                {
                    name: "references",
                    type: FieldTypeName.STRING
                }
            ]
        }
    ];

    return (
        <MissingItemsStoreContext.Provider value={store}>
            <Space className="wish-list-wrapper" direction="vertical" size={[10, 10]} style={{width: "100%"}}>
                {active ? (
                    <Row className="missing-items-buttons-wrapper" style={{width: "100%"}} wrap>
                        <Dropdown
                            overlay={
                                <Menu>
                                    {store.selectedMissingItems.length > 0 ? (
                                        <>
                                            <Menu.Item
                                                onClick={store.createOrder}
                                            >
                                                Create from Stock and In Transit (selected)
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={store.createOrderWithoutReservation}
                                            >
                                                Create only from Stock (selected)
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={store.createReservation}
                                            >
                                                Create only from In Transit (selected)
                                            </Menu.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Menu.Item
                                                onClick={store.createOrderWithFilters}
                                            >
                                                Create from Stock and In Transit {isFiltered() ? '(with current filters)' : '(for all)'}
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={store.createOrderWithoutReservationWithFilters}
                                            >
                                                Create only from Stock {isFiltered() ? '(with current filters)' : '(for all)'}
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={store.createReservationWithFilters}
                                            >
                                                Create only from In Transit {isFiltered() ? '(with current filters)' : '(for all)'}
                                            </Menu.Item>
                                        </>
                                    )}
                                </Menu>
                            }
                        >
                            <Button
                                disabled={store.selectedMissingItems.length === 0 || store.isDisabled}
                                type="primary"
                            >
                                Create order
                            </Button>
                        </Dropdown>

                        {/*{store.selectedMissingItems.length > 0 ? (*/}
                        {/*    <Button*/}
                        {/*        onClick={store.addToCartItems}*/}
                        {/*        disabled={store.isDisabled}*/}
                        {/*        type="primary"*/}
                        {/*    >*/}
                        {/*        {t("WISH_LIST.ADD_TO_BASKET")}*/}
                        {/*    </Button>*/}
                        {/*) : (*/}
                        {/*    <Button*/}
                        {/*        onClick={store.openAddAllToCartModal}*/}
                        {/*        type="primary"*/}
                        {/*    >*/}
                        {/*        {t("WISH_LIST.ADD_ALL_AVAILABLE_TO_BASKET")}*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                        <Button
                            disabled={!store.dataHandler.items.length}
                            onClick={store.openDownloadAllModal}
                            type="primary"
                        >
                            {t("WISH_LIST.DOWNLOAD")}
                        </Button>
                        <Button
                            danger
                            type="primary"
                            disabled={!store.dataHandler.items.length}
                            onClick={store.hideAllMissingItems}
                        >
                            {t("WISH_LIST.HIDE_ALL")}
                        </Button>
                    </Row>
                ) : null}
                {store.selectedMissingItems.length > 0 ? (
                    <Alert
                        message={
                            <Space direction={"horizontal"} style={{justifyContent: "space-between"}}>
                                <span>Selected items: {store.selectedMissingItems.length}</span>
                                <Button size={"small"} type={"primary"} onClick={() => store.checkArticles([])}>Clear</Button>
                            </Space>
                        }
                        type="info"
                        style={{ marginTop: 20 }}
                    />
                ) : null}
                <FilteredTable
                    reloadOnLngChange
                    columns={columns}
                    columnsConfigs={{columns: convertTableColumnsToColumnConfigs(columns), pageKey: "M"}}
                    filterGroups={filters}
                    loading={store.dataHandler.loading}
                    rowSelection={active ? {
                        type: "checkbox",
                        selectedRowKeys: store.selectedMissingItems,
                        preserveSelectedRowKeys: true,
                        ...rowSelection,
                    } : null}
                    dataHandler={store.dataHandler}
                />

                <MissingItemsAddAllAvailableModal
                    visible={store.addAllModalShown}
                    warehouses={agentDataStore.account!.warehouses}
                    onClose={store.closeAddAllToCart}
                />

                <Export
                    title={"WISH_LIST.DOWNLOAD"}
                    withBasicExportFields
                    isShown={store.downloadAllModalShown}
                    config={{
                        link: "/api/v3/accounts/" + agentDataStore.account!.id + "/cart/missing-items",
                    }}
                    additionalDefaultValues={{withStock: false}}
                    onClose={store.closeDownloadAllModal}
                >
                    <Col span={24}>
                        <Form.Item
                            name="warehouses"
                            label={t("WAREHOUSES.TITLE")}
                        >
                            <Select
                                allowClear
                                defaultOpen
                                autoFocus
                                mode="multiple"
                                placeholder={t("WAREHOUSES.TITLE")}
                                getPopupContainer={trigger => trigger.parentElement}
                            >
                                {agentDataStore.account!.warehouses.map(({warehouse}) => (
                                    <Select.Option
                                        key={warehouse.id}
                                        value={warehouse.id}
                                    >
                                        {warehouse.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="includeInTransit"
                            valuePropName={"checked"}
                        >
                            <Checkbox>{t("MISSING_ITEMS_EXPORT.WITH_TRANSIT")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="includeFullApplied"
                            valuePropName={"checked"}
                        >
                            <Checkbox>{t("MISSING_ITEMS_EXPORT.INCLUDE_FULL_APPLIED")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="withStock"
                            valuePropName={"checked"}
                        >
                            <Checkbox>{t("WISH_LIST.WITH_STOCK")}</Checkbox>
                        </Form.Item>
                    </Col>
                </Export>
            </Space>
        </MissingItemsStoreContext.Provider>

    );
})
