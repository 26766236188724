import React, {useMemo} from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Typography} from "antd";
import {columnsFactory} from "./containers.items.columns";
import {containersService} from "../../../../../services/containers/containers.service";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {useParams} from "react-router-dom";
import {SmartTable} from "../../../../common/smart-table/smart-table";
import {SmartTableDataHandler} from "../../../../common/smart-table/smart-table.data-handler";

export const ContainersItems: React.FC<ComponentPropsFromRoute> = () => {

    const { id } = useParams<{ id: string }>();
    const t = useTranslate();
    const columns = useMemo(() => columnsFactory(t), []);
    const dataHandler = useMemo(() => new SmartTableDataHandler(r => containersService.getItems(parseInt(id!), r)), []);

    return <>
        <Typography.Title level={3}>{t("CONTAINERS.CONTAINER.ITEMS")}</Typography.Title>
        <SmartTable
            handler={dataHandler}
            columns={columns}
        />
    </>
}