import React from "react";
import {Checkbox, Col, Form, Row, Select} from "antd";
import {config} from "../config";
import {useTranslate} from "../../../../../hooks/translate.hook";

export interface ProductFieldsProps {
    loading: boolean;
    courierName: string;
}

export const AutoSelectNearestOffice: React.FC<ProductFieldsProps> = ({loading, courierName}) => {

    const t = useTranslate();

    if (!config[courierName]?.useAutoSelectNearestOffice) {
        return null;
    }

    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    name="autoSelectNearestOffice"
                    label="Nearest Office"
                    valuePropName={"checked"}
                >
                    <Checkbox disabled={loading}>Auto Select Nearest Office</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    )
}