import React, {useState} from "react";
import {observer} from "mobx-react";
import {Col, Form, Input, Modal, Row} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {AxiosError} from "axios";
import {InvoiceDataStoreContext} from "../invoice.data.store";
import {useRequiredStore} from "../../../../../utils/store";
import {ErrorResponseData} from "../../../../../models/core/response";
import TextArea from "antd/es/input/TextArea";

interface InvoiceEditModalProps {
    visible: boolean,
    onClose: () => void
}

export const InvoiceEditModal: React.FC<InvoiceEditModalProps> = observer(({visible, onClose}) => {
    const t = useTranslate();
    const store = useRequiredStore(InvoiceDataStoreContext);

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={t("INVOICE.EDIT")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"order_data"}
                initialValues={{
                    paymentTerms: store.invoice?.paymentTerms || 0,
                    shipmentCondition: store.invoice?.shipmentCondition,
                    taxLawRef: store.invoice?.taxLawRef,
                    customText: store.invoice?.customText
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        values.paymentTerms = parseInt(values.paymentTerms);
                        setLoading(true);
                        store.edit(values)
                            .then(() => {
                                form.resetFields();
                                return onClose();
                            }, (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() =>
                                // onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="paymentTerms"
                            label={t("PAYMENT_TERMS")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input type="text" placeholder={t("PAYMENT_TERMS")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="shipmentCondition"
                            label={t("SHIPMENT_CONDITION")}
                        >
                            <Input type="text" placeholder={t("SHIPMENT_CONDITION")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="taxLawRef"
                            label={t("TAX_LAW_REF")}
                        >
                            <Input type="text" placeholder={t("TAX_LAW_REF")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="customText"
                            label={t("DOCUMENT.CUSTOM_TEXT")}
                        >
                            <TextArea placeholder={t("DOCUMENT.CUSTOM_TEXT")}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});
